import React from 'react';
import { IonContent, IonPage, IonButton } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';

const ErrorPage: React.FC = () => {
  const history = useHistory();

  const goToStart = () => {
    history.push('/'); // Assumes your start page is at the root route
  };

  useHideIonTabBar();

  return (
    <IonPage>
      <IonContent className="login-content">
        <div className='login-overlay flex flex-col items-center justify-center h-full'>
          <p className="mb-6 text-3xl text-center">Oops!</p>
          <p className='mb-6 text-2xl text-center'>Something went wrong</p>

          <IonButton onClick={goToStart} expand="block" className="w-64">
            Go Back to Start
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ErrorPage;