import React from 'react';
import { IonCard, IonCardContent } from '@ionic/react';
import './MarketCard.scss';
import Coin from '../Coin';
import { formatNumber } from '../../utils';
import { MAX_LEVEL } from '../../redux/resourcesSlice';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';

interface MarketCardProps {
  image: string;
  title: string;
  profitPerHour: number;
  level: number;
  price: number;
  disabled?: boolean;
  onClick?: () => void
}

const MarketCard: React.FC<MarketCardProps> = ({ image, title, profitPerHour, level, price, onClick, disabled = false }) => {
  return (
    <IonCard className="market-card" onClick={onClick} disabled={disabled}>
      <IonCardContent class='card-content'>
        <div className="profit-info">
          <div>
            <img src={image} alt={title} className="card-image rounded-md" />
          </div>

          <div className='title'>
            {title}
          </div>

          <div className='profit-group'>
            <span className="profit-text">Profit per hour</span>
            <div className="details">
              <Coin size={18} marginRight={6} />
              <span className="profit-amount text-base">+{formatNumber(profitPerHour, 0)}</span>
            </div>
          </div>
        </div>

        <hr className='divider' />

        <div className="level-info">
          <span className="level-text">lvl {level >= MAX_LEVEL + 1 ? 'max' : level}</span>
          {level < MAX_LEVEL + 1 &&
            <div className="price-details">
              <Coin size={20} />
              <span className="price-amount text-xl">{formatNumber(price, 0)}</span>
            </div>
          }

          {level >= MAX_LEVEL + 1 &&
            <div className="price-details">
              <HBIcon size={24} name={IconNameEnum.Check} />
            </div>
          }
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default MarketCard;
