import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Coins.scss';
import Coin from '../Coin';
import { selectCurrentCoins } from '../../redux/userSlice';
import { COINS_TARGET_ATTR } from '../CoinsAnimation/CoinsAnimation';

const formatPoints = (points: number) => {
  return points.toLocaleString();
};

interface CoinsProps {
  animate?: boolean;
}

const Coins: React.FC<CoinsProps> = ({ animate = true }) => {
  const targetCoins = useSelector(selectCurrentCoins);
  const [currentPoints, setCurrentPoints] = useState(targetCoins);

  useEffect(() => {
    setCurrentPoints(targetCoins); // Ensure state is in sync initially

    if (!animate) return;
    if (currentPoints === targetCoins) return;

    const increment = targetCoins > currentPoints ? 1 : -1;
    const duration = 1000; // total duration of animation in ms
    const stepTime = Math.abs(Math.floor(duration / (targetCoins - currentPoints)));

    const timer = setInterval(() => {
      setCurrentPoints((prevPoints: number) => {
        if (prevPoints === targetCoins) {
          clearInterval(timer);
          return prevPoints;
        }
        return prevPoints + increment;
      });
    }, stepTime);

    return () => clearInterval(timer);
  }, [targetCoins]);

  return (
    <div className="points-container">
      <Coin size={36} />
      <span  {...{ [COINS_TARGET_ATTR]: true }} className="points">{formatPoints(currentPoints)}</span>
    </div>
  );
};

export default Coins;
