import React from 'react';
import './Coin.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';

interface CoinProps {
  size?: number;
  marginRight?: number;
  className?: string;
}

const Coin: React.FC<CoinProps> = ({ size = 24, marginRight = 8, className = '' }) => {
  return (
      <HBIcon name={IconNameEnum.Coin} size={size} customStyle={{ marginRight }} className={className} />
  );
};

export default Coin;
