import React, { useEffect, useRef } from 'react';
import { useAppDispatch } from '../../hooks';
import { fetchUser, updateUserLastActive } from '../../redux/userSlice';
import { useViewport, Viewport } from '@telegram-apps/sdk-react';

const LastActiveTracker = () => {
  const updateIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null); // For debouncing
  const lastExpandedRef = useRef<boolean | null>(null);
  const lastFetchRef = useRef<number | null>(null); // Track last fetch time
  const timestampRef = useRef(new Date().getTime()); // Track the last active time
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const componentRef = useRef(document);

  const dispatch = useAppDispatch();

  const debounceUpdateLastActive = () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current); // Clear previous timer if event happens before 10 seconds
    }

    debounceTimerRef.current = setTimeout(() => {
      dispatch(updateUserLastActive());
    }, 10000); // Wait for 10 seconds before sending the update
  };

  const fetchUserIfNotRecentlyFetched = () => {
    const now = Date.now();
    const lastFetch = lastFetchRef.current;

    if (!lastFetch || now - lastFetch > 1 * 60 * 1000) { // 1-minute threshold
      dispatch(fetchUser());
      lastFetchRef.current = now; // Update last fetch time
    }
  };

  const onResume = () => {
    console.log('onResume');
    debounceUpdateLastActive();
    if (!updateIntervalRef.current) {
      updateIntervalRef.current = setInterval(debounceUpdateLastActive, 5 * 60 * 1000); // Update every 5 minutes
    }
    fetchUserIfNotRecentlyFetched();
  };

  const onPause = () => {
    console.log('onPause');
    debounceUpdateLastActive();
    if (updateIntervalRef.current) {
      clearInterval(updateIntervalRef.current);
      updateIntervalRef.current = null;
    }
  };

  useEffect(() => {
    const checkResume = () => {
      const current = new Date().getTime();
      if (current - timestampRef.current > 4000) { // If more than 4 seconds passed
        const event = new Event('hb-resume', { bubbles: true, cancelable: true });
        document.dispatchEvent(event);
      }
      timestampRef.current = current; // Update the timestamp with the current time
    };

    intervalRef.current = setInterval(checkResume, 1000); // Check every 1 second

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current); // Clean up interval on unmount
      }
    };
  }, []);

  let viewport: Viewport | undefined;
  try {
    viewport = useViewport();

    viewport?.on('change:isExpanded', (expanded) => {
      if (lastExpandedRef.current !== expanded) {
        lastExpandedRef.current = expanded;
        if (expanded) {
          onResume();
        } else {
          onPause();
        }
      }
    });
  } catch (e) {
    console.log('Viewport undefined');
  }

  useEffect(() => {
    window.addEventListener('blur', onPause);
    componentRef.current.addEventListener('hb-resume', onResume);

    return () => {
      componentRef.current.removeEventListener('hb-resume', onResume);
      window.removeEventListener('blur', onPause);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default LastActiveTracker;
