import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState, Status } from './store';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import axiosInstance from '../axiosConfig';

export enum DailyBoosterType {
  FULL_ENERGY = 'FULL_ENERGY',
}

export interface DailyBooster {
  id: number;
  type: DailyBoosterType;
  name: string;
  description: string;
  limitPerDay: number,
  basePrice: number,
  createdAt: string,
  updatedAt: string,
  usedTodayCount: number,
  requiredWaitTimeInMinutes: number;
  lastTimeUsed: string;
}

interface DailyBoostersState {
  boosters: DailyBooster[];
  status: Status;
  error: string | null;
}

const initialState: DailyBoostersState = {
  boosters: [],
  status: 'idle',
  error: null,
};

export const fetchDailyBoosters = createAsyncThunk<DailyBooster[]>(
  'dailyBoosters/fetchDailyBoosters',
  async () => {
    const response = await axiosInstance.get('/daily-boosters');
    return response.data;
  }
);

export const addDailyBoosterToUser = createAsyncThunk('dailyBoosters/addDailyBoosterToUser', async ({ boosterId, timeZone }: { boosterId: number, timeZone: string }) => {
  const response = await axiosInstance.put(`/daily-boosters/${boosterId}/add`, { timeZone });
  return response.data;
});

const dailyBoostersSlice = createSlice({
  name: 'dailyBoosters',
  initialState,
  reducers: {
    boosterUsed(state, action: PayloadAction<{ boosterId: number }>) {
      const booster = state.boosters.find(b => b.id === action.payload.boosterId);
      if (booster) {
        booster.lastTimeUsed = new Date().toISOString();
        booster.usedTodayCount += 1;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyBoosters.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchDailyBoosters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.boosters = action.payload;
      })
      .addCase(fetchDailyBoosters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch permanent boosters';
      })
      .addCase(addDailyBoosterToUser.fulfilled, (state, action) => {})
  },
});

export const {
  boosterUsed
} = dailyBoostersSlice.actions;

export const selectAllDailyBoosters = (state: RootState) => state.dailyBoosters.boosters;
export const selectDailyBoostersStatus = (state: RootState) => state.dailyBoosters.status;


export default dailyBoostersSlice.reducer;
