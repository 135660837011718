import { Config } from '.';

const staging: Config = {
  name: 'staging',
  apiBaseUrl: 'https://buzzy-bee-api-stg.hivebits.io',
  telegramBot: 'hb_bb_stg_bot',
  adsGramBlock: '3830',
};

export default staging;
