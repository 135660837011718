// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './PermanentBoostersModal.scss';
import { PermanentBooster, PermanentBoosterType, addPermanentBoosterToUser } from '../../redux/permanentBoostersSlice';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { boostTotalEnergy, deductCurrentCoins, selectCurrentCoins, setCoinsPerTap, setTotalEnergy } from '../../redux/userSlice';
import { closeOutline } from 'ionicons/icons';
import { formatNumber } from '../../utils';
import { permanentBoostersIcons } from '../../pages/BoostPage';

interface BoostersModalProps {
  booster: PermanentBooster | null;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const BoosterModal: React.FC<BoostersModalProps> = ({ booster, isOpen, onDidDismiss }) => {
  const currentCoins = useAppSelector(selectCurrentCoins);

  const modal = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const [present] = useIonToast();
  const boostAddedToast = () => {
    present({
      message: 'Booster successfully added!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const handleAddBooster = async (booster: PermanentBooster) => {

    const { type, id: boosterId, newPrice: amount, newLevel: level } = booster;

    await dispatch(addPermanentBoosterToUser({ boosterId }));

    // Update current coins UI
    dispatch(deductCurrentCoins({ amount }))

    if (type === PermanentBoosterType.TAP) {
      // Update coins per click UI
      dispatch(setCoinsPerTap(level + 1))
    } else if (type === PermanentBoosterType.ENERGY) {
      // Update total energy UI
      dispatch(boostTotalEnergy())
    }

    boostAddedToast();
    modal.current?.dismiss();
  }

  if (!booster) return;

  const disableButton = currentCoins < booster.newPrice;

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-7'>
            <HBIcon name={permanentBoostersIcons[booster.type]} size={92} />
          </div>

          <h2 className='text-2xl mb-4'>{booster.name}</h2>

          <p className='mb-4 text-center'>
            {booster.description}
          </p>

          <p className='mb-4 text-center'>
            +{booster.increment} {booster.specification} {booster.newLevel}
          </p>

          <div className='flex items-center text-3xl'>
            <HBIcon name={IconNameEnum.Coin} size={36} className='mr-2' /> {formatNumber(booster.newPrice)} <HBIcon name={IconNameEnum.Dot} size={42} /> {booster.newLevel} lvl
          </div>
        </div>

        <IonButton disabled={disableButton} className='mb-6' expand='block' onClick={() => handleAddBooster(booster)}>
          {!disableButton ? 'Go ahead' : 'Insufficient founds'}
        </IonButton>
      </div>
    </IonModal>
  );
};

export default BoosterModal;
