import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { Status } from './store';

// Define the state interface
export interface VideoTask {
  id: number;
  title: string;
  description: string;
  videoUrl: string;
  isCompleted: boolean;
  reward: number;
  startedAt: string | null; // Allow null for tasks not started yet
  requiredWatchTimeMinutes: number;
}

interface VideoTaskState {
  videoTasks: VideoTask[];
  status: Status;
  error: string | null;
}

// Initial state
const initialState: VideoTaskState = {
  videoTasks: [],
  status: 'idle',
  error: null,
};

// Create an async thunk to fetch video tasks from the API
export const fetchVideoTasksState = createAsyncThunk('videoTasks/fetchVideoTasksState', async () => {
  const response = await axiosInstance.get('/tasks/video');
  return response.data;
});

// Create an async thunk to start a video task
export const startVideoTasks = createAsyncThunk('videoTasks/startVideoTasks', async (videoTaskId: number) => {
  const response = await axiosInstance.put(`/tasks/video/${videoTaskId}`);
  return response.data;
});

export const claimVideoTasksReward = createAsyncThunk('videoTasks/claimVideoTasksReward', async (videoTaskId: number) => {
  const response = await axiosInstance.put(`/tasks/video/${videoTaskId}/claim`);
  return response.data;
});

// Create the slice
const videoTaskSlice = createSlice({
  name: 'videoTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideoTasksState.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVideoTasksState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.videoTasks = action.payload;
      })
      .addCase(fetchVideoTasksState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch video tasks';
      })
      .addCase(startVideoTasks.fulfilled, (state, action) => {
        const id: number = action.payload;
        const task = state.videoTasks.find(t => t.id === id);
        if (task) {
          task.startedAt = new Date().toISOString();
        }
      })
      .addCase(claimVideoTasksReward.fulfilled, (state, action) => {
        const id: number = action.payload.videoTask.id;
        const task = state.videoTasks.find(t => t.id === id);
        if (task) {
          task.isCompleted = true;
        }
      });
  },
});

export default videoTaskSlice.reducer;

export const selectAllVideoTasks = (state: { videoTasks: VideoTaskState }) => state.videoTasks.videoTasks;
export const selectVideoTasksStatus = (state: { videoTasks: VideoTaskState }) => state.videoTasks.status;
export const getVideoTasksError = (state: { videoTasks: VideoTaskState }) => state.videoTasks.error;
