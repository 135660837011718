import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonSegment, IonSegmentButton, IonLabel, IonList, IonItem } from '@ionic/react';
import './LeaderboardPage.scss';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../hooks';
import HBIcon from '../components/HBIcon';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import { fetchLeaderboard, selectCoinsLeaderboard, selectCurrentUserRank, selectIsPaidSponsor, selectIsTeamMember, selectLeaderboardStataus, selectOrdersLeaderboard, selectTelegramUser, TelegramUser } from '../redux/userSlice';
import HBBack from '../components/HBBack';
import { getUserLevelAndNext } from '../utils';
import HBSpinner from '../components/HBSpinner';

enum Segment {
  GENERAL = 'general',
  // COINS = 'coins',
  ORDERS = 'orders'
}

const LeaderboardPage: React.FC = () => {
  const location = useLocation();
  const [selectedSegment, setSelectedSegment] = useState<Segment>(Segment.GENERAL);

  const dispatch = useAppDispatch();
  const leaderboardStataus = useAppSelector(selectLeaderboardStataus);

  const coinsLeaderboard = useAppSelector(selectCoinsLeaderboard);
  const ordersLeaderboard = useAppSelector(selectOrdersLeaderboard);
  const isTeamMember = useAppSelector(selectIsTeamMember);
  const isPaidSponsor = useAppSelector(selectIsPaidSponsor);

  const telegramUser = useAppSelector(selectTelegramUser);
  const currentUserRank = useAppSelector(selectCurrentUserRank);

  const leaderboard = selectedSegment === Segment.GENERAL ? coinsLeaderboard : ordersLeaderboard;
  const userRank = selectedSegment === Segment.GENERAL ? currentUserRank?.totalCoinsRank : currentUserRank?.ordersRank;
  const userTotalEarnings =
    selectedSegment === Segment.GENERAL ? currentUserRank?.totalCoins : currentUserRank?.totalOrders;
  const showLevel = selectedSegment === Segment.GENERAL;

  useHideIonTabBar();

  useEffect(() => {
    if (leaderboardStataus === 'idle') {
      dispatch(fetchLeaderboard());
    }
  }, [leaderboardStataus])

  const handleSegmentChange = (e: CustomEvent) => {
    setSelectedSegment(e.detail.value);
  };

  const getInitials = (user: TelegramUser | undefined, hide: boolean = false) => {
    let initials = '';
    if (user && !hide) {
      if (user.firstName && user.lastName) {
        initials = user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase();
      } else if (user.firstName) {
        initials = user.firstName.substring(0, 2).toUpperCase();
      } else if (user.username) {
        initials = user.username.substring(0, 2).toUpperCase();
      }
    }

    // If all fields are empty, return '- -'
    if (!initials) {
      initials = '- -';
    }

    return initials;
  }

  const getName = (user: TelegramUser | undefined, hide: boolean = false) => {
    let name = '';
    if (user && !hide) {
      if (user.firstName) {
        name = user.firstName;
      } else if (user.username) {
        name = user.username;
      }
    }

    // If all fields are empty, return '- -'
    if (!name) {
      name = '- -';
    }

    return name;
  }

  const getUserLevelName = (totalCoins = 0) => {
    return getUserLevelAndNext(totalCoins).currentLevel.name.replace(' Beekeeper', '');
  }

  if (!location.pathname.startsWith('/leaderboard')) return;

  return (
    <IonPage>
      <IonContent fullscreen>

        <HBBack />

        {/* <ProfileHeader /> */}
        <div className='leaderboard-page flex flex-col items-center'>
          <div className='mb-8 coin-icon animate__animated animate__slideInDown'>
            <HBIcon name={IconNameEnum.Award} size={92} />
          </div>

          <div className='font-bold text-3xl mb-8 animate__animated animate__headShake'>Leaderboard</div>

          <IonSegment value={selectedSegment} onIonChange={handleSegmentChange}>
            <IonSegmentButton value={Segment.GENERAL}>
              <IonLabel>General</IonLabel>
            </IonSegmentButton>
            {/* <IonSegmentButton value={Segment.COINS}>
              <IonLabel>Coins</IonLabel>
            </IonSegmentButton> */}
            <IonSegmentButton value={Segment.ORDERS}>
              <IonLabel>Orders</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          {leaderboardStataus === 'succeeded' ?
            <div className="w-full">
              <IonList className='list w-full  p-4'>
                {leaderboard.map((user, index) => (
                  <IonItem key={index} className='rounded-md mb-4 w-full'>
                    <div className='hb-avatar mr-4 text-base rounded-full p-2 flex items-center justify-center'>
                      {getInitials(user.telegramUser, user.isAdmin || user.isTeamMember || user.isPaidSponsor)}
                    </div>
                    <div className='text-base'>
                      <div className='flex'>
                        <div className='mr-2 truncate max-w-52'>{getName(user.telegramUser, user.isAdmin || user.isTeamMember || user.isPaidSponsor)}</div>
                      </div>
                      <div className='flex mt-1 text-lg items-center'>
                        <HBIcon name={IconNameEnum.Coin} size={20} className='mr-2' />
                        {user.totalCoins.toLocaleString()}
                        {showLevel && <div className='truncate ml-2 w-32 text-sm'>({getUserLevelName(user.totalCoins)})</div>}
                      </div>
                    </div>
                    <div slot='end' className='text-2xl text-zinc-300'>{index + 1}</div>
                  </IonItem>
                ))}
              </IonList>

              {currentUserRank &&
                <IonList className='list w-full p-4 fixed bottom-0 pr-8 hb-background' style={{ background: '#262a30' }}>
                  <IonItem className='rounded-md mb-4 w-full' style={{ border: '1px solid var(--ion-color-primary-shade)' }}>
                    <div className='hb-avatar mr-4 text-base rounded-full p-2 flex items-center justify-center'>
                      {isPaidSponsor ? '- -' : getInitials(telegramUser)}
                    </div>
                    <div className='text-base'>
                      <div className='flex'>
                        <div className='mr-2 truncate max-w-36'>
                          {isPaidSponsor ? '- -' : getName(telegramUser)}
                        </div>
                      </div>
                      <div className='flex mt-1 text-lg items-center'>
                        <HBIcon name={IconNameEnum.Coin} size={20} className='mr-2' />
                        {(userTotalEarnings || 0).toLocaleString()}
                        {showLevel && <div className='truncate ml-2 w-32 text-sm'>({getUserLevelName(userTotalEarnings)})</div>}
                      </div>
                    </div>
                    <div slot='end' className='text-2xl text-zinc-300'>{userRank}</div>
                  </IonItem>
                </IonList>
              }
            </div> : <HBSpinner />}

        </div>
      </IonContent>
    </IonPage>
  );
};

export default LeaderboardPage;
