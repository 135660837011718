import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonLabel, IonList, IonItem, IonThumbnail, IonSkeletonText } from '@ionic/react';
import './BoostPage.scss';
import Coins from '../components/Coins';
import { useLocation } from 'react-router';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../redux/store';
import { PermanentBooster, PermanentBoosterType, fetchPermanentBoosters, selectPermanentBoostersStatus } from '../redux/permanentBoostersSlice';
import Coin from '../components/Coin';
import HBIcon from '../components/HBIcon';
import PermanentBoostersModal from '../components/PermanentBoostersModal';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import HBBack from '../components/HBBack';
import { formatNumber } from '../utils';
import { DailyBooster, DailyBoosterType, fetchDailyBoosters, selectAllDailyBoosters, selectDailyBoostersStatus } from '../redux/dailyBoostersSlice';
import { DateTime } from 'luxon';
import DailyBoostersModal from '../components/DailyBoostersModal';

export const permanentBoostersIcons = {
  [PermanentBoosterType.TAP]: IconNameEnum.Tap,
  [PermanentBoosterType.ENERGY]: IconNameEnum.EnergyDrink
};

export const dailyBoostersIcons = {
  [DailyBoosterType.FULL_ENERGY]: IconNameEnum.Thunder,
};

const BoostPage: React.FC = () => {
  const location = useLocation();
  const [isPermanentModelOpen, setIsPermanentModelOpen] = useState(false);
  const [selectedPermanentBooster, setSelectedPermanentBooster] = useState<PermanentBooster | null>(null);

  const [isDailyModelOpen, setIsDailyModelOpen] = useState(false);
  const [selectedDailyBooster, setSelectedDailyBooster] = useState<DailyBooster | null>(null);
  const [remainingTimes, setRemainingTimes] = useState<{ [key: number]: number }>({});

  const dispatch = useAppDispatch();
  const { boosters } = useAppSelector((state: RootState) => state.permanentBoosters);
  const dailyBoosters = useAppSelector(selectAllDailyBoosters);

  const [dailyBoosterModalKey, setDailyBoosterModalKey] = useState(`daily_${Date.now()}`);
  const [permanentBoosterModalKey, setPermanentBoosterModalKey] = useState(`permanent_${Date.now()}`);

  const permanentBoostersStatus = useAppSelector(selectPermanentBoostersStatus);
  const dailyBoostersStatus = useAppSelector(selectDailyBoostersStatus);


  const calculateRemainingTimes = () => {
    const newRemainingTimes: { [key: number]: number } = {};
    dailyBoosters.forEach((booster) => {
      const now = DateTime.now();
      const lastUsed = DateTime.fromISO(booster.lastTimeUsed);
      const timePassed = now.diff(lastUsed, 'minutes').minutes;
      const timeLeft = booster.requiredWaitTimeInMinutes - timePassed;
      newRemainingTimes[booster.id] = Math.max(timeLeft * 60, 0); // Convert to seconds
    });
    setRemainingTimes(newRemainingTimes);
  }

  useEffect(() => {
    if (permanentBoostersStatus === 'idle') {
      dispatch(fetchPermanentBoosters());
    }

    if (dailyBoostersStatus === 'idle') {
      dispatch(fetchDailyBoosters());
    }

    calculateRemainingTimes();
    const interval = setInterval(() => {
      calculateRemainingTimes();
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch, dailyBoosters, permanentBoostersStatus, dailyBoostersStatus]);

  const openPermanentBoostersModal = (booster: PermanentBooster) => {
    setSelectedPermanentBooster(booster);
    setPermanentBoosterModalKey(Date.now().toString())
    setIsPermanentModelOpen(true);
  };

  const openDailyBoostersModal = (booster: DailyBooster) => {
    if (remainingTimes[booster.id] > 0 || booster.limitPerDay - booster.usedTodayCount === 0) {
      return;
    }

    setSelectedDailyBooster(booster);
    setDailyBoosterModalKey(Date.now().toString())
    setIsDailyModelOpen(true);
  };


  if (!location.pathname.startsWith('/boost')) return;

  return (
    <IonPage>
      <IonContent fullscreen>
        <HBBack />
        <div className="boost-page">
          <div className='balance animate__animated animate__slideInDown'>
            <IonLabel className='label text-zinc-400 mb-4'>Your Balance</IonLabel>
            <Coins animate={false} />
          </div>

          <IonLabel className='text-lg'>Free daily boosters</IonLabel>

          <IonList className='boosters-list'>
            {dailyBoosters.map((booster) => (
              <IonItem
                key={booster.id}
                no-lines
                className='animate__animated animate__headShake'
                disabled={remainingTimes[booster.id] > 0 || booster.limitPerDay - booster.usedTodayCount === 0} // Disable if time is left
                onClick={() => openDailyBoostersModal(booster)}
              >
                <div slot='start'><HBIcon name={dailyBoostersIcons[booster.type]} size={40} /></div>
                <div>
                  <div className='text-lg font-bold'>
                    {booster.name}
                  </div>
                  <div className='text-sm'>
                    {booster.limitPerDay - booster.usedTodayCount}/{booster.limitPerDay}
                  </div>
                  {booster.limitPerDay - booster.usedTodayCount > 0 && remainingTimes[booster.id] > 0 &&
                    <div slot='end' className='absolute bottom-0 right-1 text-sm'>
                      {`${Math.floor(remainingTimes[booster.id] / 60)} minutes left`}
                    </div>
                  }
                </div>
              </IonItem>
            ))}

            {dailyBoostersStatus !== 'succeeded' && [0].map(item => (
              <IonItem key={item} className='rounded-md mb-4'>
                <IonThumbnail slot="start" >
                  <IonSkeletonText animated={true} className='rounded-md'></IonSkeletonText>
                </IonThumbnail>
                <div className='flex flex-col'>
                  <div className='text-base mb-1'><IonSkeletonText animated={true} className='w-32' /></div>
                  <div className='flex items-center text-lg font-medium'><IonSkeletonText animated={true} className='w-8' /></div>
                </div>
              </IonItem>
            ))}
          </IonList>

          <IonLabel className='text-lg'>Boosters</IonLabel>

          <IonList className='boosters-list'>
            {boosters.map((booster) => (
              <IonItem
                key={booster.id}
                className='animate__animated animate__headShake'
                no-lines
                onClick={() => openPermanentBoostersModal(booster)}
              >
                <div slot='start'><HBIcon name={permanentBoostersIcons[booster.type]} size={40} /></div>
                <div className='text-lg font-bold'>
                  {booster.name}
                </div>
                <div slot='end' className='flex items-center text-lg'>
                  <Coin size={24} /> {formatNumber(booster.newPrice)} <HBIcon name={IconNameEnum.Dot} size={24} /> lvl {booster.newLevel}
                </div>
              </IonItem>
            ))}
            {permanentBoostersStatus !== 'succeeded' && [0, 1].map(item => (
              <IonItem key={item} className='rounded-md mb-4'>
                <IonThumbnail slot="start" >
                  <IonSkeletonText animated={true} className='rounded-md'></IonSkeletonText>
                </IonThumbnail>
                <div className='flex flex-col'>
                  <div className='text-base mb-1'><IonSkeletonText animated={true} className='w-32' /></div>
                  <div className='flex items-center text-lg font-medium'><IonSkeletonText animated={true} className='w-8' /></div>
                </div>
              </IonItem>
            ))}
          </IonList>
        </div>
      </IonContent>

      <div key={permanentBoosterModalKey}>
        <PermanentBoostersModal booster={selectedPermanentBooster} isOpen={isPermanentModelOpen} onDidDismiss={() => setIsPermanentModelOpen(false)} />
      </div>
      <div key={dailyBoosterModalKey}>
        <DailyBoostersModal booster={selectedDailyBooster} isOpen={isDailyModelOpen} onDidDismiss={() => setIsDailyModelOpen(false)} />
      </div>
    </IonPage>
  );
};

export default BoostPage;