import { levels } from "./constants";

export const formatNumber = (num: number, fixed?: number): string => {
  if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1_000) {
      return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return fixed?.toString() ? num.toFixed(fixed) : num.toString();
}

export const chunkArray = (array: any[], chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const getUserLevelAndNext = (totalCoins: number): {
  currentLevel: any;
  nextLevel:  any;
  coinsToNextLevel:  number;
  percentageToNextLevel:  number;
  levelIndex:  number;
} => {
  let currentLevel = levels[0]; // Default to the first level
  let nextLevel = levels[1];
  let levelIndex = 0

  for (let i = 0; i < levels.length; i++) {
    if (totalCoins >= levels[i].value) {
      currentLevel = levels[i];
      levelIndex = i;
      nextLevel = levels[i + 1] || null; // Set the next level, or null if there is no next level
    } else {
      break;
    }
  }

  const coinsToNextLevel = nextLevel ? nextLevel.value - totalCoins : 0;
  const percentageToNextLevel = nextLevel ? totalCoins / nextLevel.value : 1;

  return {
    currentLevel,
    nextLevel,
    coinsToNextLevel,
    percentageToNextLevel, // Format to 4 decimal places
    levelIndex,
  };
};
