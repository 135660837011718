// src/pages/ProductsPage.tsx
import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonModal, IonIcon, IonLabel, useIonToast } from '@ionic/react';
import { useAppDispatch } from '../../hooks';
import './SocialMediaTasksModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';
import { formatNumber } from '../../utils';
import Coin from '../Coin';
import { SocialMediaTask, SocialMediaTaskType, claimSocialMediaTasksReward, startSocialMediaTasks } from '../../redux/socialMediaTasksSlice';
import { DateTime } from 'luxon';
import { addCoinsFromOrder } from '../../redux/userSlice';
import { useAnalytics } from '../../contexts/AnalyticsContext';

interface TasksModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  icon: IconNameEnum;
  task: SocialMediaTask;
  smallActionButton: boolean;
}

const SocialMediaTasksModalWrapper: React.FC<{
  isOpen: boolean;
  onDidDismiss: () => void;
  icon: IconNameEnum;
  task: SocialMediaTask | undefined;
  smallActionButton: boolean;
}> = (props) => {
  const { task, ...other } = props;

  if (!task) return;

  return <SocialMediaTasksModal task={task} {...other} />
}


const SocialMediaTasksModal: React.FC<TasksModalProps> = ({ isOpen, onDidDismiss, icon, task, smallActionButton = false }) => {

  const dispatch = useAppDispatch();

  const modal = useRef<HTMLIonModalElement>(null);

  const { title, reward } = task;

  const [startedAt, setStartedAt] = useState(task.startedAt);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(task.isCompleted);

  const { trackEvent } = useAnalytics();


  const [present] = useIonToast();
  const rewardClaimedToast = () => {
    present({
      message: 'Reward successfully claimed!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const taskNotCompletedToast = () => {
    present({
      message: 'Task not completed.',
      duration: 1000,
      position: 'top',
      color: 'danger'
    });
  }

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (!task.isCompleted && startedAt) {
      const updateTimer = () => {
        const minutesDifference = Math.floor(DateTime.now().diff(DateTime.fromISO(startedAt || ''), 'minutes').minutes);
        const remainingTime = Math.max(task.requiredWatchTimeMinutes - minutesDifference, 0);
        setTimeLeft(remainingTime);
        setIsButtonEnabled(remainingTime === 0);
      };

      updateTimer(); // Initial call to set the timer immediately
      timerId = setInterval(updateTimer, 60000); // Check every minute

      return () => {
        if (timerId) clearInterval(timerId); // Clean up interval on component unmount
      };
    }

  }, [task, startedAt]);

  const handleStartSocialMediaTasks = async (activeTask: SocialMediaTask) => {
    trackEvent('button', 'click', `start ${activeTask.type}`);
    if (!startedAt) {
      dispatch(startSocialMediaTasks(activeTask.id));
      setStartedAt(new Date().toISOString());
    }
    window.open(activeTask.url, '_blank')
  };

  const handleClaimStartSocialMediaTask = async () => {
    trackEvent('button', 'click', `claim ${task.type}`);
    const res: any = await dispatch(claimSocialMediaTasksReward(task.id));
    if (res.error) {
      taskNotCompletedToast();
    } else if (res.payload) {
      setIsCompleted(!!res.payload.isRewardClaimed);
      dispatch(addCoinsFromOrder({ amount: task.reward }))
      rewardClaimedToast();
    }
  };


  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content task-modal'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={icon} className='icon' size={72} />
          </div>

          <h2 className='text-3xl mb-4 text-center'>{title}</h2>
          {task.subtitle && <p className='mb-4 text-zinc-400'>{task.subtitle}</p>}

          {smallActionButton && <IonButton className='mb-4' onClick={() => handleStartSocialMediaTasks(task)}>
            <div className='px-8'>
              {task.type === SocialMediaTaskType.TWITTER_POST ? 'Open post' : 'Join'}
            </div>
          </IonButton>}

          {startedAt && !task.isCompleted && timeLeft > 0 && (
            <IonLabel class='text-center mb-4'>
              Wait {timeLeft > 59 ? '1h' : `${timeLeft} min`} for moderation to check to claim the prize
            </IonLabel>
          )}

          <div className='flex text-2xl'><Coin size={28} /> +{formatNumber(reward)}</div>

        </div>

        {!isCompleted &&
          <IonButton disabled={!isButtonEnabled} color={isButtonEnabled ? 'primary' : 'medium'} className='mb-6' expand='block' onClick={() => handleClaimStartSocialMediaTask()}>
            Check
          </IonButton>
        }
      </div>
    </IonModal >
  );
};

export default SocialMediaTasksModalWrapper;
