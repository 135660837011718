// src/pages/ProductsPage.tsx
import React, { Fragment, useEffect, useRef } from 'react';
import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel, IonButton, IonList, IonModal, IonAvatar, IonIcon } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchProducts, selectAllProducts, getProductsStatus, getProductsError, Product } from '../../redux/productSlice';
import './ProductsModal.scss';
import Coin from '../Coin';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';
import HBSpinner from '../HBSpinner';
import { formatNumber, getUserLevelAndNext } from '../../utils';
import { selectTotalCoins } from '../../redux/userSlice';

interface ProductsModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}

const ProductsPage: React.FC<ProductsModalProps> = ({ isOpen, onDidDismiss }) => {
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectAllProducts);
  const productsStatus = useAppSelector(getProductsStatus);
  const error = useAppSelector(getProductsError);
  const totalCoins = useAppSelector(selectTotalCoins);

  const { currentLevel } = getUserLevelAndNext(totalCoins);

  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    if (isOpen && productsStatus === 'idle') {
      dispatch(fetchProducts());
    }
  }, [isOpen, productsStatus, dispatch]);

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content'>
        {productsStatus === 'loading' && <HBSpinner />}
        {productsStatus === 'succeeded' && (
          <Fragment>
            <div className='flex justify-end text-3xl my-1'>
              <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
            </div>
            <IonList class='products-list'>
              {products.map((product: Product) => (
                <IonItem key={product.id} no-lines disabled={currentLevel.level < product.unlockLevel}>
                  <IonAvatar slot="start">
                    <img src={`/assets/${product.image}`} alt="Avatar" />
                  </IonAvatar>
                  <IonLabel>
                    <h2>{product.name}</h2>
                    <div style={{ fontSize: '11px' }}>unlock on lvl {product.unlockLevel}</div>
                  </IonLabel>
                  <div slot="end" className='production'>
                    <div className='production-rate'>
                      <div className='production-rate'>Production per 1h</div>
                      <div className='flex text-lg'>
                        <HBIcon name={IconNameEnum.TimeIsMoney} className='mr-2' size={18} />
                        {formatNumber(product.productionRatePerHour, 0)}
                      </div>
                    </div>
                    <div className='profit-rate'>
                      <div className='production-rate'>Profit per hour</div>
                      <div className='coin text-lg'><Coin size={18} /> {formatNumber(product.productionRatePerHour * product.valueInCoins, 0)}</div>
                    </div>
                  </div>
                </IonItem>
              ))}
            </IonList>
          </Fragment>
        )}
        {productsStatus === 'failed' && <div>{error}</div>}

        <IonButton expand='block' onClick={() => modal.current?.dismiss()}>
          Got it!
        </IonButton>
      </div>
    </IonModal>
  );
};

export default ProductsPage;
