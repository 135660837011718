import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from './store';
import axiosInstance from '../axiosConfig';

interface AdsgramState {
  adViews: number;
  remainingViews: number;
  lastAdWatchedAt: string | null;
  currentAdView: {
    id: number | null;
    token: string | null;
    nonce: string | null;
    obfuscatedTimestamp: string | null;
  };
  status: Status;
  error: string | null;
}

const initialState: AdsgramState = {
  adViews: 0,
  remainingViews: 20,
  lastAdWatchedAt: null,
  currentAdView: {
    id: null,
    token: null,
    nonce: null,
    obfuscatedTimestamp: null,
  },
  status: 'idle',
  error: null,
};

export const fetchAdViewStatus = createAsyncThunk(
  'adsgram/fetchAdViewStatus',
  async () => {
    const response = await axiosInstance.get(`adsgram/status`);
    return response.data;
  }
);

export const startAdView = createAsyncThunk(
  'adsgram/startAdView',
  async () => {
    const response = await axiosInstance.post(`adsgram/start`);
    return response.data;
  }
);

export const completeAdView = createAsyncThunk(
  'adsgram/completeAdView',
  async (_, { getState }) => {
    const state = getState() as { adsgram: AdsgramState };
    const { id, token, nonce, obfuscatedTimestamp } = state.adsgram.currentAdView;

    if (!id || !token || !nonce || !obfuscatedTimestamp) {
      throw new Error('No active ad view');
    }

    const response = await axiosInstance.post(`adsgram/complete/${id}`, {
      token,
      nonce,
      obfuscatedTimestamp
    });
    return response.data;
  }
);

export const abandonAdView = createAsyncThunk(
  'adsgram/abandonAdView',
  async (_, { getState }) => {
    const state = getState() as { adsgram: AdsgramState };
    const { id } = state.adsgram.currentAdView;

    if (!id) {
      throw new Error('No active ad view');
    }

    const response = await axiosInstance.post(`adsgram/abandon/${id}`);
    return response.data;
  }
);

const adsgramSlice = createSlice({
  name: 'adsgram',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    clearCurrentAdView: (state) => {
      state.currentAdView = {
        id: null,
        token: null,
        nonce: null,
        obfuscatedTimestamp: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdViewStatus.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdViewStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.adViews = action.payload.viewCount;
        state.remainingViews = action.payload.remainingViews;
        state.lastAdWatchedAt = action.payload.lastAdWatchedAt;
      })
      .addCase(fetchAdViewStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch ad view status';
      })
      .addCase(startAdView.fulfilled, (state, action) => {
        state.currentAdView = {
          id: action.payload.adViewId,
          token: action.payload.token,
          nonce: action.payload.nonce,
          obfuscatedTimestamp: action.payload.obfuscatedTimestamp,
        };

        state.lastAdWatchedAt = new Date().toISOString();
      })
      .addCase(startAdView.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to start ad view';
      })
      .addCase(completeAdView.fulfilled, (state) => {
        state.adViews += 1;
        state.remainingViews -= 1;
        state.currentAdView = {
          id: null,
          token: null,
          nonce: null,
          obfuscatedTimestamp: null,
        };
      })
      .addCase(completeAdView.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to complete ad view';
      })
      .addCase(abandonAdView.fulfilled, (state) => {
        state.currentAdView = {
          id: null,
          token: null,
          nonce: null,
          obfuscatedTimestamp: null,
        };
      })
      .addCase(abandonAdView.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to abandon ad view';
      });
  },
});

export const { resetError, clearCurrentAdView } = adsgramSlice.actions;

export default adsgramSlice.reducer;

export const getAdsgramStatus = (state: { adsgram: AdsgramState }): Status => state.adsgram.status;
export const selectRemainingViews = (state: { adsgram: AdsgramState }): number => state.adsgram.remainingViews;
export const selectCurrentAdView = (state: { adsgram: AdsgramState }) => state.adsgram.currentAdView;
export const selectLastWatchedAt = (state: { adsgram: AdsgramState }): string | null => state.adsgram.lastAdWatchedAt;
