import { Config } from ".";

const local: Config = {
  name: 'local',
  apiBaseUrl: 'http://localhost:3000',
  telegramBot: 'hb_bb_dev_bot',
  adsGramBlock: '3830',
};

export default local;
