import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonModal, IonIcon } from '@ionic/react';
import './ProgressModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';
import Coin from '../Coin';
import { formatNumber } from '../../utils';
import { useCoinsAnimation } from '../../contexts/CoinsAnimationContext';
import { useStorage } from '../../contexts/StorageContext';
import { COINS_ANIMATION_KEY } from '../../pages/SettingsPage';

interface ProgressModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  earning: number;
}

const ProgressModal: React.FC<ProgressModalProps> = ({ isOpen, onDidDismiss, earning = 0 }) => {
  const modal = useRef<HTMLIonModalElement>(null);

  const { triggerAnimation } = useCoinsAnimation();

  const { storageValues, isInitialized } = useStorage();
  const [coinsAnimation, setCoinsAnimation] = useState(true);

  useEffect(() => {
    if (isInitialized && COINS_ANIMATION_KEY in storageValues) {
      setCoinsAnimation(storageValues[COINS_ANIMATION_KEY] !== 'false');
    }
  }, [storageValues, isInitialized]);

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content progress-modal'>
        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={IconNameEnum.Bee} className='icon' size={72} />
          </div>

          <div className='flex text-4xl font-semibold mb-4'>
            <Coin size={42} /> +{formatNumber(earning)}
          </div>

          <p className='text-center text-xl mb-4'>The bees have started working for you.</p>

        </div>

        <IonButton color='primary' className='mb-6' expand='block' onClick={() => {
          if (coinsAnimation) {
            triggerAnimation();
          }
          modal.current?.dismiss()
        }}>
          Cheers to the bees! 💚
        </IonButton>

      </div>
    </IonModal>
  );
};

export default ProgressModal;
