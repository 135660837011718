import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, Status } from './store';
import axiosInstance from '../axiosConfig';
import { Product } from './productSlice';
import { Environment } from './environmentsSlice';

export enum CharacterType {
  REGULAR = 'REGULAR',
  PREMIUM = 'PREMIUM'
}

export interface CharacterProduct {
  influencePercentage: number;
  product: Product
}

export interface CharacterEnvironment {
  influencePercentage: number;
  environment: Environment;
}

export interface Character {
  id: number;
  type: CharacterType;
  name: string;
  description: string;
  advantages: string[];
  disadvantages: string[];
  image: string;
  characterProduct: CharacterProduct[];
  characterEnvironment: CharacterEnvironment[];
}

interface CharactersState {
  characters: Character[];
  character: Character | null;
  status: Status;
  characterStatus: Status,
  error: string | null;
}

const initialState: CharactersState = {
  characters: [],
  character: null,
  status: 'idle',
  characterStatus: 'idle',
  error: null,
};

export const fetchCharacters = createAsyncThunk('characters/fetchCharacters', async () => {
  const response = await axiosInstance.get<Character[]>('/characters');
  return response.data;
});

export const fetchCharacter = createAsyncThunk('characters/fetchCharacter', async (characterId: number) => {
  const response = await axiosInstance.get<Character>(`/characters/${characterId}`);
  return response.data;
});

const charactersSlice = createSlice({
  name: 'characters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCharacters.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCharacters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.characters = action.payload;
      })
      .addCase(fetchCharacters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch characters';
      })
      .addCase(fetchCharacter.pending, (state) => {
        state.characterStatus = 'loading';
      })
      .addCase(fetchCharacter.fulfilled, (state, action) => {
        state.characterStatus = 'succeeded';
        state.character = action.payload;
      })
      .addCase(fetchCharacter.rejected, (state) => {
        state.characterStatus = 'failed';
      });
  },
});

export default charactersSlice.reducer;

export const selectAllCharacters = (state: RootState): Character[] => state.characters.characters;
export const getCharactersStatus = (state: RootState): Status => state.characters.status;
export const getCharactersError = (state: RootState): string | null => state.characters.error;
export const selectCharacter = (state: RootState): Character | null => state.characters.character;
export const selectCharacterStatus = (state: RootState): Status => state.characters.characterStatus;
