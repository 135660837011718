import React, { useEffect } from 'react';
import { IonContent, IonLabel, IonPage, IonSpinner } from '@ionic/react';
import { useLocation } from 'react-router-dom';
import './Login.scss';
import axiosInstance from '../axiosConfig';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import { InitData, retrieveLaunchParams, useInitData } from '@telegram-apps/sdk-react';
import { REF_PARAM_PREFIX } from '../constants';
import SocialMedia from '../components/SocialMedia';

const Login: React.FC = () => {
  const location = useLocation();

  useHideIonTabBar();


  let initData: InitData | undefined;
  let initDataRaw: string | undefined;;
  try {
    const launchParams = retrieveLaunchParams();
    initDataRaw = launchParams.initDataRaw;

    initData = useInitData();
  } catch(e) {
    console.log('not tg env')
  }


  useEffect(() => {
    localStorage.clear();

    const params = new URLSearchParams(location.search);
    const mockId = params.get('mock');
    if (mockId) {
      loginWithMockId(mockId);
    } else {
      if (initData && initDataRaw) {
        loginWithTelegram();
      }
    }


  }, [location]);

  const loginWithTelegram = async () => {
    try {
      const refData = initData && initData.startParam && initData.startParam.startsWith(REF_PARAM_PREFIX) ?
        { ref_id: initData.startParam.replace(REF_PARAM_PREFIX, '') } : {};

      const response = await axiosInstance.post(`/auth`, {
        ...refData,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }, {
        headers: {
          Authorization: `tma ${initDataRaw}`
        }
      });
      const { token } = response.data;
      localStorage.setItem('token', token);
      console.log('response.data', response.data)
      window.location.href = '/'
    } catch (err) {
      console.log(err);
    }
  };

  const loginWithMockId = async (mockId: string) => {
    try {
      const response = await axiosInstance.post(`/auth?mock=${mockId}`, {},
        {
          headers: {
            Authorization: `tma ${initDataRaw}`
          }
        }
      );
      const { token } = response.data;
      localStorage.setItem('token', token);
      window.location.href = '/'
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  return (
    <IonPage>
      <IonContent className="login-content">
      </IonContent>
      <div className='login-overlay text-xl flex flex-col'>
        {/* <div> */}
        <IonSpinner className='mb-8' name="circular" color={'primary'} style={{ width: '42px', height: '42px' }} />
        {/* </div> */}

        <div className='text-4xl mb-2'><IonLabel color='primary'>Buzzy Bee</IonLabel></div>
        <div className='text-base text-zinc-200'>by HiveBits</div>

        {/* <div className='w-24 flex justify-between absolute bottom-4'>
          <HBIcon name={IconNameEnum.Twitter} size={38}/>
          <HBIcon name={IconNameEnum.Telegram} size={38} />
        </div> */}

        <div className='absolute bottom-4 flex flex-col items-center'>
          <div className='text-base text-zinc-200 mb-2'>More info in official channels</div>
          <SocialMedia size={38} />
        </div>
      </div>
    </IonPage>
  );
};

export default Login;
