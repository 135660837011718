import React, { createContext, useContext, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga4';
import env from '../environments';

interface Analytics {
  trackEvent: (category: string, action: string, label?: string) => void;
  trackPageView: (path: string) => void;
}

const AnalyticsContext = createContext<Analytics | undefined>(undefined);

export const AnalyticsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const trackPageView = useCallback((path: string) => {
    if (env.name === 'production') {
      ReactGA.send({ hitType: 'pageview', page: path });
    }
  }, []);

  const trackEvent = useCallback((category: string, action: string, label?: string) => {
    if (env.name === 'production') {
      ReactGA.event({
        category,
        action,
        label
      });
    }
  }, []);

  useEffect(() => {
    if (env.name === 'production') {
      // Initialize GA
      if (!ReactGA.isInitialized) {
        ReactGA.initialize('G-W5BBKLH1WL'); // Replace with your actual GA4 Measurement ID
      }

      // Track page view on initial load
      trackPageView(window.location.pathname);

      // Function to handle route changes
      const handleRouteChange = () => {
        trackPageView(window.location.pathname);
      };

      // Listen for popstate events (browser back/forward)
      window.addEventListener('popstate', handleRouteChange);

      // Monkey-patch history methods
      const originalPushState = history.pushState;
      const originalReplaceState = history.replaceState;

      history.pushState = function () {
        originalPushState.apply(this, (arguments as any));
        handleRouteChange();
      };

      history.replaceState = function () {
        originalReplaceState.apply(this, (arguments as any));
        handleRouteChange();
      };

      return () => {
        window.removeEventListener('popstate', handleRouteChange);
        history.pushState = originalPushState;
        history.replaceState = originalReplaceState;
      };
    }
  }, [trackPageView]);

  return (
    <AnalyticsContext.Provider value={{ trackEvent, trackPageView }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = (): Analytics => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};