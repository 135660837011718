import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from './store';
import axiosInstance from '../axiosConfig';
import { Product } from './productSlice';


interface Customer {
  name: string;
  avatar: string;
  type: string;
}

export interface Order {
  id: number;
  customer: Customer;
  product: Product;
  quantity: number;
  level: number;
  isFulfilled: boolean;
  createdAt: string;
  hide?: boolean;
}

interface OrderState {
  orders: Order[];
  lastGenerationTime: Date | null;
  status: Status;
  error: string | null;
}

const initialState: OrderState = {
  orders: [],
  lastGenerationTime: null,
  status: 'idle',
  error: null,
};

// Async thunk to fetch orders for a user
export const fetchOrdersForUser = createAsyncThunk('orders/fetchOrdersForUser', async () => {
  const response = await axiosInstance.get(`/orders`);
  return response.data;
});

// Async thunk to fulfill an order
export const fulfillOrder = createAsyncThunk('orders/fulfillOrder', async (orderId: number) => {
  const response = await axiosInstance.patch(`/orders/${orderId}/fulfill`);
  return response.data;
});

const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersForUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersForUser.fulfilled, (state, action) => {
        state.status = 'succeeded';

        const { orders, lastGenerationTime } = action.payload;
        state.orders = orders;
        state.lastGenerationTime = lastGenerationTime;
      })
      .addCase(fetchOrdersForUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(fulfillOrder.fulfilled, (state, action) => {
        const fulfilledOrder = action.payload;
        const order = state.orders.find(o => o.id === fulfilledOrder.id);
        if (order) {
          order.hide = true;
        }
      })
  },
});

export default orderSlice.reducer;

export const selectAllOrders = (state: any) => state.orders.orders;
export const getLastGenerationTime = (state: any) => state.orders.lastGenerationTime;
export const getOrderStatus = (state: any) => state.orders.status;
export const getOrderError = (state: any) => state.orders.error;
