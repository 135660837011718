import { IonCard, IonCol, IonRow } from "@ionic/react";
import './InviteFriendCard.scss';
import Coin from "../Coin/Coin";

interface InviteFriendCardProps {
    title: string;
    coins: string;
    present?: number;
}

const InviteFriendCard: React.FC<InviteFriendCardProps> = ({title, coins, present}) => {
    return (
        <IonCard className='ion-padding'>
            <IonRow className='invite-friend'>
                <img className='ion-margin-end present-img animate__animated animate__zoomIn' src={`/present${present}.png`} />
                <IonCol>
                    <span className="title">{title}</span>
                    <IonRow className="content-row">
                        <Coin size={12} marginRight={3}/>
                        <span className="coins">+{coins}</span>
                        <span>For you and your friend</span>
                    </IonRow>
                </IonCol>
            </IonRow>
        </IonCard>
    );
}

export default InviteFriendCard;