// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchProducts } from '../../redux/productSlice';
import './ResourceModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { addIdleCoins, deductCurrentCoins, selectCurrentCoins } from '../../redux/userSlice';
import { closeOutline } from 'ionicons/icons';
import { ResourceWithInfluence } from '../Resources/ResourcesList';
import { MAX_LEVEL, upgradeResource } from '../../redux/resourcesSlice';
import { setStatusIdle as setStockStatusIdle } from '../../redux/stockSlice';
import { formatNumber } from '../../utils';

interface ResourceModalProps {
  resource: ResourceWithInfluence | null;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const ResourceModal: React.FC<ResourceModalProps> = ({ resource, isOpen, onDidDismiss }) => {
  const currentCoins = useAppSelector(selectCurrentCoins);

  const modal = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const [present] = useIonToast();
  const resourceUpgradedToast = () => {
    present({
      message: 'Resource successfully upgraded!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const handleUpgradeResource = async (resourceId: number, amount: number, points: number) => {
    const res = await dispatch(upgradeResource({ resourceId }));

    if (res && res.payload) {
      // Update profit per hour UO
      dispatch(addIdleCoins({ amount: res.payload.idleCoinsDifference }))
      // Update current coins UI
      dispatch(deductCurrentCoins({ amount }));
    }

    dispatch(fetchProducts())
    dispatch(setStockStatusIdle())

    resourceUpgradedToast();
    modal.current?.dismiss();
  }

  if (!resource) return;

  const disableButton = currentCoins < resource.price;

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content resource-modal'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-7'>
            <img className='icon w-36 rounded-md' src={`${resource.icon}`} />
          </div>

          <h2 className='text-2xl mb-4'>{resource.name}</h2>

          <div className='mb-8 mt-2'>
            {resource.influence.map((influence, index) => (
              <p key={index} className='flex text-zinc-400'><HBIcon name={IconNameEnum.Dot} /> increase {influence.product.name} production for {(influence.influencePercentage * 100).toFixed(0)}%</p>
            ))}
          </div>

          <div className='flex items-center text-3xl'>
            <HBIcon name={IconNameEnum.Coin} size={36} className='mr-2' /> {formatNumber(resource.price)} <HBIcon name={IconNameEnum.Dot} size={42} /> {resource.level} lvl
          </div>
        </div>

        {resource.level >= MAX_LEVEL + 1 ?
          <IonButton disabled={true} className='mb-6' expand='block'>
            You've reached max level
          </IonButton>
          :
          <IonButton disabled={disableButton} className='mb-6' expand='block' onClick={() => handleUpgradeResource(resource.id, resource.price, resource.idleCoins)}>
            {!disableButton ? 'Go ahead' : 'Insufficient founds'}
          </IonButton>
        }
      </div>
    </IonModal>
  );
};

export default ResourceModal;
