// src/pages/ProductsPage.tsx
import React, { useEffect } from 'react';
import { IonItem, IonLabel, IonList, IonAvatar, IonPage, IonContent } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchProducts, selectAllProducts, getProductsStatus } from '../../redux/productSlice';
import './ProductsStock.scss';
import Coin from '../Coin';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { formatNumber, getUserLevelAndNext } from '../../utils';
import HBSpinner from '../HBSpinner';
import { fetchStock, getStockStatus, selectAllStock } from '../../redux/stockSlice';
import { selectTotalCoins } from '../../redux/userSlice';


const ProductsStock: React.FC = () => {

  const dispatch = useAppDispatch();
  const stockStatus = useAppSelector(getStockStatus);
  const stock = useAppSelector(selectAllStock);

  const totalCoins = useAppSelector(selectTotalCoins);
  const { currentLevel } = getUserLevelAndNext(totalCoins);

  useEffect(() => {
    if (stockStatus === 'idle') {
      dispatch(fetchStock())
    }
  }, [dispatch, stockStatus])

  if (stockStatus !== 'succeeded') return <HBSpinner />;

  return (
    // This is hack to fix ion-page-invisible because we have nested IonPage
    <IonPage style={{ opacity: 1 }}>
      <IonContent fullscreen>
        {stockStatus === 'succeeded' ?
          <IonList class='products-stock-list mt-8'>
            {stock.map((product: any) => (
              <IonItem key={product.id} no-lines disabled={currentLevel.level < product.unlockLevel}>
                <IonAvatar slot="start">
                  <img src={`/assets/${product.image}`} alt="Avatar" />
                </IonAvatar>
                <IonLabel>
                  <h2>{product.name}</h2>
                  <div style={{ fontSize: '11px' }}>unlock on lvl {product.unlockLevel}</div>
                </IonLabel>
                <div slot="end" className='production'>
                  <div className='production-rate'>
                    <div className='production-rate'>In stock</div>
                    <div className='flex text-lg'>
                      <HBIcon name={IconNameEnum.InStock} size={24} className='mr-1' />
                      {formatNumber(product.currentStock)}
                    </div>
                  </div>
                  <div className='profit-rate'>
                    <div className='production-rate'>Earnings</div>
                    <div className='coin text-lg'><Coin size={18} />{formatNumber(product.currentStock * product.valueInCoins)}</div>
                  </div>
                </div>
              </IonItem>
            ))}
          </IonList> : <HBSpinner />
        }
      </IonContent>
    </IonPage>
  );
};

export default ProductsStock;
