import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, Status } from './store';
import axiosInstance from '../axiosConfig';
import { Product } from './productSlice';


export enum EnvironmentType {
  REGULAR = 'REGULAR',
  PREMIUM = 'PREMIUM'
}

export interface EnvironmentProduct {
  influencePercentage: number;
  product: Product
}

export interface Environment {
  id: number;
  type: EnvironmentType,
  name: string;
  description: string;
  advantages: string[];
  disadvantages: string[];
  image: string;
  environmentProduct: EnvironmentProduct[]
}

interface EnvironmentsState {
  environments: Environment[];
  environment: Environment | null;
  status: Status;
  environmentStatus: Status;
  error: string | null;
}

const initialState: EnvironmentsState = {
  environments: [],
  environment: null,
  status: 'idle',
  environmentStatus: 'idle',
  error: null,
};

export const fetchEnvironments = createAsyncThunk('environments/fetchEnvironments', async () => {
  const response = await axiosInstance.get<Environment[]>('/environments');
  return response.data;
});

export const fetchEnvironment = createAsyncThunk('environments/fetchEnvironment', async (environmentId: number) => {
  const response = await axiosInstance.get<Environment>(`/environments/${environmentId}`);
  return response.data;
});

const environmentsSlice = createSlice({
  name: 'environments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEnvironments.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEnvironments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.environments = action.payload;
      })
      .addCase(fetchEnvironments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch environments';
      })
      .addCase(fetchEnvironment.pending, (state) => {
        state.environmentStatus = 'loading';
      })
      .addCase(fetchEnvironment.fulfilled, (state, action) => {
        state.environmentStatus = 'succeeded';
        state.environment = action.payload;
      })
      .addCase(fetchEnvironment.rejected, (state) => {
        state.environmentStatus = 'failed';
      });
  },
});

export default environmentsSlice.reducer;

export const selectAllEnvironments = (state: RootState): Environment[] => state.environments.environments;
export const getEnvironmentsStatus = (state: RootState): Status => state.environments.status;
export const getEnvironmentsError = (state: RootState): string | null => state.environments.error;
export const selectEnvironment = (state: RootState): Environment | null => state.environments.environment;
export const selectEnvironmentStatus = (state: RootState): Status => state.environments.environmentStatus;
