import React from 'react';
import './SocialMedia.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';

interface CoinProps {
  size?: number;
  className?: string;
}

const links = [
  {
    icon: IconNameEnum.Twitter,
    url: 'https://x.com/hivebits_io'
  },
  {
    icon: IconNameEnum.Telegram,
    url: 'https://t.me/+-hbp1S-7AYw0ZDVk'
  }
]

const SocialMedia: React.FC<CoinProps> = ({ size = 24, className = '' }) => {

  const handleSocialLinkClick = (link: any) => {
    window.open(link.url, '_blank')
  }

  return (
    <div className={`w-24 flex justify-between ${className}`}>
      {links.map((link, index) => (
        <div  key={index} onClick={() => handleSocialLinkClick(link)}>
          <HBIcon name={link.icon} size={size} />
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;
