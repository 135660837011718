// src/pages/ProductsPage.tsx
import React, { useRef, useState } from 'react';
import { IonButton, IonModal, IonIcon } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './TutorialModal.scss';
import { selectCharacterImage } from '../../redux/userSlice';
import { closeOutline } from 'ionicons/icons';

interface TutorialModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}

interface Step {
  id: number,
  title: string;
  description: string;
  image: string;
}

const steps: Step[] = [
  {
    id: 1,
    title: 'Tap and earn coins',
    description: 'You can use boosters and tricky strategies',
    image: 'assets/tutorial/1_tap.png',
  },
  {
    id: 2,
    title: 'Upgrade resources',
    description: 'Level up your resources and increase production rate and passive income',
    image: 'assets/tutorial/2_resources.png',
  },
  {
    id: 3,
    title: 'Check your stock',
    description: 'Keep an eye on your products in stock and track available resources',
    image: 'assets/tutorial/3_stock.png',
  },
  {
    id: 4,
    title: 'Fulfill orders',
    description: 'The most important task! Happy customers are key. Fulfill orders to boost revenue and shape your success.',
    image: 'assets/tutorial/4_orders.png',
  },

  {
    id: 5,
    title: 'Complete tasks and earn',
    description: 'Fulfill various tasks to earn more and unlock new opportunities',
    image: 'assets/tutorial/5_earn.png',
  },
  {
    id: 6,
    title: 'Invite friends',
    description: 'Invite your friends and earn rewards together',
    image: 'assets/tutorial/6_friends.png',
  }
]

export const TUTORIAL_MODAL_SHOWN_LS_KEY = 'tutorial_modal_shown_ls_key';

const TutorialModal: React.FC<TutorialModalProps> = ({ isOpen, onDidDismiss }) => {

  const modal = useRef<HTMLIonModalElement>(null);

  const [currentStep, setCurrentStep] = useState<Step>(steps[0]);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const characterImage = useAppSelector(selectCharacterImage);
  const characterUrl = `/assets/${characterImage?.replace('tap_', '').replace('.png', '_no_background.png')}`

  const nextStep = () => {

    const nextStep = currentStepIndex + 1;
    if (nextStep >= steps.length) {
      modal.current?.dismiss();
      return;
    }

    setCurrentStepIndex(nextStep)
    setCurrentStep(steps[nextStep])
  }

  async function canDismiss(data?: any, role?: string) {
    return role !== 'gesture';
  }

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} canDismiss={canDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content tutorial-modal'>

        <div className='flex justify-end text-3xl mt-4 mr-4'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col' key={currentStep.id}>
          <div className={`flex flex-col items-center min-h-72 ${currentStepIndex !== 0 ? 'animate__animated animate__slideInRight' : ''}`}>
            <img
              className={`mb-4 tutorial-img`}
              src={currentStep.image}
            />
          </div>

          <div className='tutorial-padding min-h-3'>
            {characterImage &&
              <div className='min-w-28'>
                <img className='min-w-28' src={characterUrl} />
              </div>}

            <div className=''>
              <div className='text-xl bold mb-2'>
                {currentStep.title}
              </div>
              <div className='text-base'>
                {currentStep.description}
              </div>
            </div>
          </div>

          <div className='w-full flex justify-end p-2 next-btn'>
            <IonButton className='' onClick={() => nextStep()}>
              <div className='px-8'>
                {
                  currentStepIndex < steps.length - 1 ?
                    `Next` : `Finish`
                }
              </div>
            </IonButton>
          </div>
        </div>

        <div className='absolute bottom-4 left-4 flex'>
          {
            steps.map((step) => (
              <div key={step.id} className={`progress-step w-4 h-1 mr-1 rounded-md ${step.id === currentStep.id ? 'active' : ''}`}></div>
            ))
          }
        </div>
      </div>
    </IonModal>
  );
};

export default TutorialModal;
