import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IonPage, IonContent, IonButton, IonRadio, IonRadioGroup, IonFooter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchEnvironments, selectAllEnvironments, getEnvironmentsStatus, getEnvironmentsError, Environment, EnvironmentProduct, EnvironmentType } from '../redux/environmentsSlice';
import { selectApiaryId, setEnvironment } from '../redux/userSlice';
import { updateApiaryEnvironment } from '../redux/apiarySlice';
import './EnvironmentSelector.scss';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import HBSpinner from '../components/HBSpinner';
import EnvironmentCard from '../components/EnvironmentCard';

const EnvironmentSelector: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const environments = useAppSelector(selectAllEnvironments);
  const environmentsStatus = useAppSelector(getEnvironmentsStatus);
  const error = useAppSelector(getEnvironmentsError);
  const apiaryId = useAppSelector(selectApiaryId);

  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const toggleExpand = (id: number) => {
    setExpandedItems(prevState =>
      prevState.includes(id) ? prevState.filter(i => i !== id) : [...prevState, id]
    )
  }

  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment>();

  useHideIonTabBar();

  useEffect(() => {
    if (environmentsStatus === 'idle') {
      dispatch(fetchEnvironments());
    } else if (environmentsStatus === 'succeeded') {
      setSelectedEnvironment(environments[0])
    }
  }, [environmentsStatus, dispatch]);

  const handleSelectEnvironment = async () => {
    if (apiaryId && selectedEnvironment) {
      await dispatch(updateApiaryEnvironment({ apiaryId, environmentId: selectedEnvironment.id }));
      dispatch(setEnvironment({ environment: selectedEnvironment }))
      history.push('/character');
    }
  };

  const filterDisadvantages = (arr: EnvironmentProduct[]) => {
    return arr.filter(i => i.influencePercentage < 0);
  }

  const countDisadvantages = (arr: EnvironmentProduct[]): number => {
    return filterDisadvantages(arr).length;
  }

  if (!location.pathname.startsWith('/environment')) return;

  let content;
  if (environmentsStatus === 'loading') {
    content = <HBSpinner />;
  } else if (environmentsStatus === 'succeeded') {
    content = (
      <div className='environment-selector'>
        <IonRadioGroup value={selectedEnvironment?.id.toString()} onIonChange={(ev) => {
          ev.stopPropagation();
          const environment = environments.find(e => e.id == ev.detail.value);
          if (environment) {
            setSelectedEnvironment(environment)
          }
        }}>
          {environments.map((env, index) => (
            <Fragment key={index}>
              <EnvironmentCard
                environment={env}
                expanded={expandedItems.includes(index)}
                selected={selectedEnvironment?.id === env.id}
                disabled={env.type === EnvironmentType.PREMIUM}
                onToggleExpand={() => toggleExpand(index)}
                onSelectedEnvironment={() => setSelectedEnvironment(env)} >
                <IonRadio className='absolute top-1 right-1' value={env.id.toString()}></IonRadio>
              </EnvironmentCard>
            </Fragment>
          ))
          }
        </IonRadioGroup >
      </div >
    );
  } else if (environmentsStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <IonPage className='environment-selector'>
      <IonContent>
        <h1 className='text-center text-2xl font-bold mt-4'>Select your ideal environment</h1>
        {content}
      </IonContent>
      <IonFooter>
        <IonButton className='mx-4' expand="full" color="primary" onClick={() => handleSelectEnvironment()}>Next</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default EnvironmentSelector;
