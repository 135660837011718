import React, { useEffect, useState } from 'react';
import {
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  IonContent,
  IonPage,
} from '@ionic/react';
import './OrdersList.scss';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Order, fetchOrdersForUser, getLastGenerationTime, getOrderError, getOrderStatus, selectAllOrders } from '../../redux/orderSlice';
import HBSpinner from '../HBSpinner';
import Coin from '../Coin';
import { formatNumber } from '../../utils';
import OrderModal from '../OrderModal';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';

const orders2 = [
  { customer: 'John Doe', avatar: 'customer_john_doe.jpg', products: [{ name: 'Honey', quantity: 10 }, { name: 'Bee Bread', quantity: 5 }] },
  { customer: 'Jane Smith', avatar: 'customer_jane_smith.jpg', products: [{ name: 'Pollen', quantity: 20 }, { name: 'Propolis', quantity: 8 }] },
  { customer: 'Michael Johnson', avatar: 'customer_michael_johnson.jpg', products: [{ name: 'Honey', quantity: 15 }, { name: 'Wax', quantity: 10 }] },
  { customer: 'Emily Davis', avatar: 'customer_emily_davis.jpg', products: [{ name: 'Bee Bread', quantity: 7 }, { name: 'Royal Jelly', quantity: 3 }] },
  { customer: 'David Martinez', avatar: 'customer_david_martinez.jpg', products: [{ name: 'Propolis', quantity: 12 }, { name: 'Pollen', quantity: 9 }] },
  { customer: 'Laura Lee', avatar: 'customer_laura_lee.jpg', products: [{ name: 'Honey', quantity: 20 }, { name: 'Royal Jelly', quantity: 5 }] },
  { customer: 'James Brown', avatar: 'customer_james_brown.jpg', products: [{ name: 'Wax', quantity: 10 }, { name: 'Pollen', quantity: 6 }] },
  { customer: 'Linda White', avatar: 'customer_linda_white.jpg', products: [{ name: 'Bee Bread', quantity: 8 }, { name: 'Honey', quantity: 15 }] },
  { customer: 'Robert Taylor', avatar: 'customer_robert_taylor.jpg', products: [{ name: 'Propolis', quantity: 10 }, { name: 'Wax', quantity: 7 }] },
  { customer: 'Sophia Green', avatar: 'customer_sophia_green.jpg', products: [{ name: 'Royal Jelly', quantity: 5 }, { name: 'Honey', quantity: 25 }] },
  { customer: 'BeeSweet Corp', avatar: 'customer_beesweet_corp.jpg', products: [{ name: 'Honey', quantity: 50 }, { name: 'Bee Bread', quantity: 30 }] },
  { customer: 'Pollen Power Inc', avatar: 'customer_pollen_power_inc.jpg', products: [{ name: 'Pollen', quantity: 40 }, { name: 'Propolis', quantity: 20 }] },
  { customer: 'Royal Nectar Ltd', avatar: 'customer_royal_nectar_ltd.jpg', products: [{ name: 'Royal Jelly', quantity: 25 }, { name: 'Honey', quantity: 35 }] },
  { customer: 'WaxWorks Co', avatar: 'customer_waxworks_co.jpg', products: [{ name: 'Wax', quantity: 30 }, { name: 'Pollen', quantity: 15 }] },
  { customer: 'Propolis Health', avatar: 'customer_propolis_health.jpg', products: [{ name: 'Propolis', quantity: 20 }, { name: 'Royal Jelly', quantity: 10 }] },
];


const OrdersList: React.FC = () => {

  const dispatch = useAppDispatch();
  const orders = useAppSelector(selectAllOrders);
  const orderStatus = useAppSelector(getOrderStatus);
  const lastGenerationTime = useAppSelector(getLastGenerationTime);
  const orderError = useAppSelector(getOrderError);

  const filteredOrders = orders.filter((o: Order) => !o.hide);

  const [isOrderModelOpen, setOrderIsModelOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [timeLeft, setTimeLeft] = useState(0);

  const openOrderModal = (order: Order) => {
    setSelectedOrder(order);
    setOrderIsModelOpen(true);
  }

  useEffect(() => {
    if (orderStatus === 'idle') {
      dispatch(fetchOrdersForUser());
    }

    let intervalId: NodeJS.Timeout;
    if (orderStatus === 'succeeded' && filteredOrders.length === 0 && lastGenerationTime) {
      // Calculate the time left until the next hour after the last generation time
      const currentTime = new Date().getTime();
      const nextGenerationTime = new Date(lastGenerationTime).getTime() + 60 * 60 * 1000;
      const initialTimeLeft = Math.max(nextGenerationTime - currentTime, 0);

      setTimeLeft(initialTimeLeft);

      // Set up a countdown timer
      intervalId = setInterval(() => {
        setTimeLeft(prevTimeLeft => {
          if (prevTimeLeft <= 1000) {
            clearInterval(intervalId);
            // Refetch orders when timer reaches zero
            dispatch(fetchOrdersForUser());
            return 0;
          }
          return prevTimeLeft - 1000;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [orderStatus, filteredOrders, lastGenerationTime, dispatch]);

  // Convert time left from milliseconds to hours, minutes, and seconds
  const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  if (orderStatus !== 'succeeded') {
    return <HBSpinner />;
  }

  return (
    // This is hack to fix ion-page-invisible because we have nested IonPage
    <IonPage style={{ opacity: 1 }}>
      <IonContent fullscreen>

        {filteredOrders.length === 0 && <div className='flex flex-col items-center mt-16'>
          <HBIcon name={IconNameEnum.Stopwatch} size={80} className='animate__animated animate__slideInLeft' />
          <div className='text-center text-lg mt-4 animate__animated animate__slideInRight'>No new orders available at the moment. Please check back in:</div>
          <div className='text-center text-3xl mt-4 animate__animated animate__zoomIn'>{`${hours ? `$hours:` : ''}${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</div>
        </div>}

        {filteredOrders.length > 0 &&
          <IonList className='orders-list mt-8'>
            {filteredOrders.map((order: Order, index: number) => (
              <IonItem className='rounded-md my-2 h-16' key={index} onClick={() => openOrderModal(order)} >
                <IonAvatar slot="start">
                  <img src={`/assets/${order.customer.avatar}`} alt="Avatar" />
                </IonAvatar>
                <IonLabel>
                  <h2>{order.customer.name}</h2>
                </IonLabel>
                <div slot="end" className="flex items-center justify-between w-48">
                  <div className='flex flex-col items-center justify-center w-20'>
                    <div className='text-xs'>Prod.</div>
                    <div className='text-base text-center'>{order.product.name}</div>
                  </div>
                  <div className='flex flex-col items-center w-16'>
                    <div className='text-xs'>Qty.</div>
                    <div className='text-lg'>{order.quantity}</div>
                  </div>
                  <div className='flex flex-col items-center w-16'>
                    <div className='text-xs'>Earnings</div>
                    <div className='coin text-lg flex'><Coin size={18} />{formatNumber(order.quantity * order.product.valueInCoins)}</div>
                  </div>
                </div>
              </IonItem>
            ))}
          </IonList>
        }
      </IonContent>

      <OrderModal order={selectedOrder} isOpen={isOrderModelOpen} onDidDismiss={() => {
        setOrderIsModelOpen(false)
      }} />
    </IonPage>
  );
};

export default OrdersList;
