import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { Status } from './store';

export const fetchStock = createAsyncThunk('stock/fetchStock', async () => {
  const response = await axiosInstance.get(`/apiaries-production`); // Adjust the URL to your API endpoint
  return response.data;
});

interface Stock {
  productId: number;
  name: string;
  productionRatePerHour: number;
  unlockLevel: number;
  valueInCoins: number;
  image: string;
  currentStock: number;
  unlocked: boolean;
}

interface StockState {
  stock: Stock[];
  status: Status;
  error: string | null;
}

const initialState: StockState = {
  stock: [],
  status: 'idle',
  error: null,
};

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setStatusIdle(state) {
      state.status = 'idle';
    },
    reduceProductStock(state, action: PayloadAction<{ productId: number, amount: number}>) {
      const { productId, amount } = action.payload;
      const productStock = state.stock.find(s => s.productId === productId);
      if (productStock) {
        productStock.currentStock = Math.max(productStock.currentStock - amount, 0);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStock.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStock.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stock = action.payload.sort((s: Stock) => s.unlockLevel);
      })
      .addCase(fetchStock.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const {
  setStatusIdle,
  reduceProductStock,
} = stockSlice.actions;

export default stockSlice.reducer;

export const selectAllStock = (state: any): Stock[] => state.stock.stock;
export const getStockStatus = (state: any): Status => state.stock.status;
export const getStockError = (state: any): string | null => state.stock.error;
