import React, { useRef } from 'react';
import { IonButton, IonModal, IonIcon } from '@ionic/react';
import './RewardModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';

interface RewardsModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  title: string;
  subtitle: string;
  icon: IconNameEnum;
  description: string;
}


const RewardsModal: React.FC<RewardsModalProps> = ({ isOpen, onDidDismiss, title, description, subtitle, icon }) => {
  const modal = useRef<HTMLIonModalElement>(null);

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content task-modal'>
        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={icon} className='icon' size={72} />
          </div>

          <div className='text-3xl mb-1 text-center'>{title}</div>
          <p className='text-zinc-400'>{subtitle}</p>

          <div className='flex my-8'>
            <HBIcon size={32} name={IconNameEnum.Megaphone} className='mr-4' />
            <div className='flex algin-center text-2xl'>TBA</div>
            {/* <HBIcon size={64} name={IconNameEnum.Dot} /> */}
          </div>

          <p className='text-center mb-4'>{description}</p>
        </div>

        <IonButton color='primary' className='mb-6' expand='block' onClick={() => modal.current?.dismiss()}>
          Got it!
        </IonButton>
      </div>
    </IonModal>
  );
};

export default RewardsModal;
