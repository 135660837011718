import React, { Fragment } from 'react';
import { IonBadge, IonCard, IonIcon, IonLabel } from '@ionic/react';
import './EnvironmentCard.scss';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { Environment, EnvironmentProduct, EnvironmentType } from '../../redux/environmentsSlice';

type IfProps = {
  children?: React.ReactNode;
};

const If: React.FC<IfProps> = ({ children }) => {
  if (children) {
    return children;
  }
  return null;
};


interface EnvironmentCard {
  environment: Environment;
  expanded: boolean;
  selected: boolean;
  disabled: boolean;
  onSelectedEnvironment: () => void;
  onToggleExpand: () => void;
  children?: React.ReactNode;
  classNames?: string;
}

const EnvironmentCard: React.FC<EnvironmentCard> = ({ environment, expanded, selected, onSelectedEnvironment, children, onToggleExpand, classNames, disabled }) => {

  const filterAdvantages = (arr: EnvironmentProduct[]) => {
    return arr.filter(i => i.influencePercentage > 0);
  }

  const filterDisadvantages = (arr: EnvironmentProduct[]) => {
    return arr.filter(i => i.influencePercentage < 0);
  }

  const countDisadvantages = (arr: EnvironmentProduct[]): number => {
    return filterDisadvantages(arr).length;
  }

  return (
    <IonCard disabled={disabled} className={classNames}>
      <div onClick={() => onSelectedEnvironment()} className={`environment-image overflow-hidden ${expanded ? 'expanded' : ''}`} style={{
        backgroundImage: `url(assets/${environment.image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        height: '180px', // Adjust height as needed
        color: 'white',
        borderRadius: '0.375rem'
      }}>
        <div className={'p-4 environment-text'}>
          <div className='text-xl' style={{ color: selected ? 'var(--ion-color-primary)' : '' }}>{environment.name}</div>
        </div>
        {environment.type === EnvironmentType.PREMIUM && <IonBadge className='absolute bottom-2 left-2'>Premium</IonBadge>}
        {!expanded && <IonIcon onClick={() => onToggleExpand()} className='absolute bottom-1 right-2 text-white' size='small' icon={chevronDownOutline} color='light' />}
        <If>
          {children}
        </If>
      </div>
      {expanded &&
        <div className='text-zinc-200 rounded-md p-4 details-list'>
          <p className='text-zinc-200 mb-2 text-base uppercase'>{environment.description}</p>
          <IonLabel className='text-base'><b>Advantages:</b></IonLabel>
          <ul className='mb-4 mt-2'>
            {filterAdvantages(environment.environmentProduct).map((adv, idx: number) => (
              <li key={idx}>Increase <b>{adv.product.name}</b> production for <b>+{adv.influencePercentage * 100}%</b></li>
            ))}
          </ul>
          {countDisadvantages(environment.environmentProduct) > 0 && (
            <Fragment>
              <IonLabel className='text-base'><b>Disadvantages:</b></IonLabel>
              <ul className='mt-2'>
                {filterDisadvantages(environment.environmentProduct).map((adv, idx: number) => (
                  <li key={idx}>Reduce <b>{adv.product.name}</b> production for <b>{adv.influencePercentage * 100}%</b></li>
                ))}
              </ul>
            </Fragment>
          )}
          {expanded && <IonIcon onClick={() => onToggleExpand()} className='absolute bottom-1 right-2 text-white' size='small' icon={chevronUpOutline} color='light' />}
        </div>}
    </IonCard>
  );
};

export default EnvironmentCard;
