// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon, IonAvatar, IonAlert } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './DeleteAccountModal.scss';
import { closeOutline } from 'ionicons/icons';
import { deleteUser, selectDeleteAccountStatus } from '../../redux/userSlice';
import { initMiniApp } from '@telegram-apps/sdk-react';
import { useAnalytics } from '../../contexts/AnalyticsContext';


interface DeleteAccountModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}


const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, onDidDismiss }) => {


  const modal = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const { trackEvent } = useAnalytics();

  const [present] = useIonToast();
  const accountDeletedToast = () => {
    present({
      message: 'Account successfully deleted!',
      duration: 1000,
      position: 'top',
      color: 'dang'
    });
  };

  const [miniApp] = initMiniApp();
  const deleteAccountStatus = useAppSelector(selectDeleteAccountStatus);
  if (deleteAccountStatus === 'deleted') {
    accountDeletedToast();
    setTimeout(() => {
      miniApp.close();
    })
  }


  // const [present] = useIonToast();
  // const orderFulfilledToast = () => {
  //   present({
  //     message: 'Order successfully fulfilled!',
  //     duration: 1000,
  //     position: 'top',
  //     color: 'success'
  //   });
  // };

  const handleDeleteAccount = async (orderId: number) => {
    // if (!order) return;

    // try {
    //   await dispatch(fulfillOrder(orderId));

    //   dispatch(addCoinsFromOrder({ amount: order ? order.quantity * order.product.valueInCoins : 0 }));
    //   dispatch(reduceProductStock({ productId: order?.product.id, amount: order.quantity}))

    //   orderFulfilledToast();
    //   modal.current?.dismiss();
    // } catch(e) {
    //   console.log(e);
    // }
  }

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content order-modal'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='font-bold text-xl text-center mb-4'>
          Are you sure you want to delete your account?
        </div>

        <p className='text-center text-sm text-zinc-400 mb-4'>
          All your data, including game progress, achievements, and purchases, will be
          permanently deleted. This action can not be undone.
        </p>

        <IonButton className='mb-2' expand='block' color='danger' id="present-alert">
          Delete account
        </IonButton>
        <IonButton className='mb-6' expand='block' color='medium' onClick={() => modal.current?.dismiss()}>
          Cancel
        </IonButton>

        <IonAlert
          header="You are about to permanently delete your account"
          trigger="present-alert"
          buttons={[
            {
              text: 'CANCEL',
              role: 'cancel',
              cssClass: 'hb-color-light',
              handler: () => {
                modal.current?.dismiss();
              },
            },
            {
              text: 'DELETE ACCOUNT',
              role: 'confirm',
              cssClass: 'hb-color-danger',
              handler: () => {
                trackEvent('button', 'click', 'delete account');
                dispatch(deleteUser())
              },
            },
          ]}
          onDidDismiss={({ detail }) => console.log(`Dismissed with role: ${detail.role}`)}
        ></IonAlert>
      </div>
    </IonModal>
  );
};

export default DeleteAccountModal;
