// src/axiosConfig.ts
import axios, { AxiosError } from 'axios';
import env from './environments';

const axiosInstance = axios.create({
  baseURL: env.apiBaseUrl, // Replace with your base URL
});

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Or however you store your token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log('error', error)
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Unauthorized, redirect to login
          if (!window.location.pathname.startsWith('/start')) {
            window.location.href = '/start';
          }
          break;
        default:
          console.error('Response error');
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
