
import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonButton, IonIcon, IonGrid, IonRow, IonCol, IonFooter } from '@ionic/react';
import { copyOutline, personAddOutline, refresh } from 'ionicons/icons';
import { initUtils } from '@telegram-apps/sdk';
import { Clipboard } from '@capacitor/clipboard';
import env from '../environments';

import './FriendsTab.scss';
import InviteFriendCard from '../components/InviteFriendCard/InviteFriendCard';
import ListOfFriends from '../components/ListOfFriends/ListOfFriends';
import { useInitData } from '@telegram-apps/sdk-react';
import { REF_PARAM_PREFIX } from '../constants';
import { useAppDispatch, useAppSelector, usePathname } from '../hooks';
import { fetchReferrals, getReferralStatus, selectAllReferrals } from '../redux/referralSlice';
import HBSpinner from '../components/HBSpinner';
import { DateTime } from 'luxon';

const FriendsTabWrapper: React.FC = () => {
  const initData = useInitData();

  const telegramId = initData?.user?.id;

  if (!telegramId) return;

  return <FriendsTab telegramId={telegramId} />
}

const FriendsTab: React.FC<{telegramId: number}> = ({ telegramId }) => {

  const [lastRefreshTime, setLastRefreshTime] = useState<DateTime | null>(null);

  const utils = initUtils();
  const shareUrl = `https://t.me/${env.telegramBot}/app?startapp=${REF_PARAM_PREFIX}${telegramId}`

  const dispatch = useAppDispatch();
  const referralStatus = useAppSelector(getReferralStatus);
  const referrals = useAppSelector(selectAllReferrals);

  useEffect(() => {
    if (referralStatus === 'idle') {
      dispatch(fetchReferrals())
    }
  }, [referralStatus])

  const writeToClipboard = async () => {
    await Clipboard.write({
      string: shareUrl
    });
  };

  const share = () => {
    utils.shareURL(shareUrl, '');
  }

  const handleRefreshReferrals = () => {
    const now = DateTime.now();
    if (!lastRefreshTime) {
      setLastRefreshTime(now);
      dispatch(fetchReferrals())
    } else {
      const diffInSeconds = now.diff(lastRefreshTime, 'seconds').seconds;
      if (diffInSeconds >= 60) {
        setLastRefreshTime(now);
        dispatch(fetchReferrals())
      }
    }
  }

  const pathname = usePathname();

  if (!pathname.startsWith('/friends')) return;

  return (
    <IonPage className='invite-friends-tab'>
      <IonContent>
        <IonGrid>
          <IonCol>
            <div className='ion-text-center ion-margin-bottom'>
              <div className='text-3xl mb-4 font-bold animate__animated animate__slideInLeft'>Invite Friends!</div>
              <div className='animate__animated animate__slideInRight'>You and your friend will receive bonuses</div>
            </div>
            <InviteFriendCard title='Invite a friend' coins={'5000'} present={1} />
            <InviteFriendCard title='Invite a friend with Telegram Premium' present={2} coins={'25,000'} />
            <IonRow className='ion-padding ion-justify-content-between'>
              <span className='ion-align-self-center'>List of your friends</span>
              <IonButton onClick={() => handleRefreshReferrals()} fill='clear' className='ion-align-self-center refresh-button'>
                <IonIcon slot='icon-only' src={refresh} />
              </IonButton >
            </IonRow>

            {
              referralStatus === 'loading' ?
              <HBSpinner className='h-32'/> :
              <ListOfFriends referrals={referrals} />
            }

          </IonCol>
        </IonGrid>
      </IonContent>
      <IonFooter >
        <IonRow className='ion-justify-content-center ion-padding'>
          <IonCol className='ion-no-padding' size='10'>
            <IonButton onClick={() => share()} expand='block' className='invite-friend-btn animate__animated animate__pulse animate__infinite animate__slow'>
              Invite a friend
              <IonIcon slot='end' src={personAddOutline}></IonIcon>
            </IonButton>
          </IonCol>
          <IonCol className='ion-no-padding' size='2'>
            <IonButton expand='block' onClick={() => writeToClipboard()}>
              <IonIcon icon={copyOutline}></IonIcon>
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </IonPage>
  );
};

export default FriendsTabWrapper;
