import React from 'react';
import { useSelector } from 'react-redux';
import './EnergyAndBoost.css';
import { useHistory } from 'react-router';
import { selectEnergy, selectTotalEnergy } from '../../redux/userSlice';
import { useAppSelector } from '../../hooks';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';

const EnergyAndBoost: React.FC = () => {

  const energy = useSelector(selectEnergy);
  const totalEnergy = useAppSelector(selectTotalEnergy);
  const history = useHistory();

  return (
    <div className="status-container">
      <div className="energy-container">
        <HBIcon name={IconNameEnum.Thunder} size={32} />
        <span className="energy">{`${energy} / ${totalEnergy}`}</span>
      </div>
      <div className="boost-container" onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        history.push('/boost');
      }}
      >
        <HBIcon name={IconNameEnum.Rocket} size={32} className='mr-2' />
        <span className="boost">Boost</span>
      </div>
    </div>
  );
};

export default EnergyAndBoost;
