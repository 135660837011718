import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import QRCode from 'react-qr-code';
import env from '../environments';
import SocialMedia from '../components/SocialMedia';
import { useLocation } from 'react-router';

const UnsupportedDevicePage: React.FC = () => {

  useHideIonTabBar();

  const qrCodeValue = `https://t.me/hivebits_bot/app`

  const location = useLocation();

  // Create a URLSearchParams object to get query parameters
  const queryParams = new URLSearchParams(location.search);

  // Access a specific query parameter
  const platform = queryParams.get('platform'); // Replace 'paramName' with your actual query param


  return (
    <IonPage>
      <IonContent fullscreen>

        <div className="mt-16 flex flex-col items-center">

          <div className='ion-text-center ion-margin-bottom mb-16'>
            <div className='text-3xl mb-4 font-bold'>Unsupported device</div>
            <div className='text-xl'>Please open app on mobile device</div>
          </div>

          <QRCode value={qrCodeValue} className='border' />

          <SocialMedia size={38} className='absolute bottom-4' />

          <p className='text-sm mt-8'>Platform: {platform}</p>
        </div>

      </IonContent>
    </IonPage>
  );
};

export default UnsupportedDevicePage;

