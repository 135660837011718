import { Config } from '.';

const production: Config = {
  name: 'production',
  apiBaseUrl: 'https://buzzy-bee-api.hivebits.io',
  adsGramBlock: '2778',
  telegramBot: 'hivebits_bot',
  tgName: 'HiveBits',
  tgToken: 'eyJhcHBfbmFtZSI6IkhpdmVCaXRzIiwiYXBwX3VybCI6Imh0dHBzOi8vdC5tZS9oaXZlYml0c19ib3QiLCJhcHBfZG9tYWluIjoiaHR0cHM6Ly9idXp6eS1iZWUuaGl2ZWJpdHMuaW8ifQ==!WD3mn27lStYAbmnmyK3I4NpidD33smLEeTznM6gF/fg='
};

export default production;
