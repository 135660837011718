import { IonCol, IonContent, IonGrid, IonLabel, IonPage, IonRow } from '@ionic/react';
import './ResourcesList.scss';
import MarketCard from '../MarketCard';
import ResourcesLabel from './ResourceLabel';
import { Fragment } from 'react/jsx-runtime';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getResourcesStatus, MAX_LEVEL, selectAllResources } from '../../redux/resourcesSlice';
import HBSpinner from '../HBSpinner';
import { formatNumber, getUserLevelAndNext } from '../../utils';
import ResourceModal from '../ResourceModal';
import { useEffect, useState } from 'react';
import { selectTotalCoins } from '../../redux/userSlice';
import { fetchReferrals, getReferralStatus, selectAllReferrals } from '../../redux/referralSlice';

interface Resource {
  name: string;
  icon: string;
  level: number;
  profitPerHour: number;
  price: number;
  group: string;
}

export interface ResourceProduct {
  name: string;
  valueInCoins: number;
  productionRatePerHour: number;
}

export interface ResourceProductInfluence {
  influencePercentage: number;
  product: ResourceProduct;
}

export interface ResourceWithInfluence {
  id: number;
  key: string;
  name: string;
  icon: string;
  unlockLevel: number;
  unlockClause: string;
  price: number;
  level: number;
  group: string;
  idleCoins: number;
  disabled: boolean;
  influence: ResourceProductInfluence[];
}

export const getUnlockClause = (resources: ResourceWithInfluence[], clause: string, referralsNum: number): boolean => {
  if (!clause) return true; // No clause, automatically unlocked.

  // Check for 'refer_friends' clause.
  if (clause.startsWith('refer_friends')) {
    const parts = clause.split('_');
    const requiredReferrals = parseInt(parts[parts.length - 1], 10);

    // Fix: referralsNum should be >= requiredReferrals.
    return referralsNum >= requiredReferrals;
  }

  // For '_level_' based clauses.
  const parts = clause.split('_level_');
  const resource = resources.find(r => r.key === parts[0]);

  if (resource) {
    const requiredLevel = parseInt(parts[1], 10);
    return resource.level >= requiredLevel;
  }

  // If no matching resource is found, clause is not fulfilled.
  return false;
}
const groupResources = (resources: ResourceWithInfluence[]) => {
  const grouped = resources.reduce((acc, resource) => {
    (acc[resource.group] = acc[resource.group] || []).push(resource);
    return acc;
  }, {} as Record<string, ResourceWithInfluence[]>);

  return Object.entries(grouped).map(([group, resources]) => {
    // Separate disabled and non-disabled resources
    const disabledResources = resources.filter((resource) => resource.disabled);
    const enabledResources = resources.filter((resource) => !resource.disabled && resource.level <= MAX_LEVEL);
    const completedResources = resources.filter((resource) => resource.level > MAX_LEVEL);

    // Sort so that all non-disabled resources come first and only one disabled at the end
    const sortedResources = [...enabledResources];

    // If there are disabled resources, push only one to the end
    if (disabledResources.length > 0) {
      sortedResources.push(disabledResources[0]); // Add only one disabled resource
    }

    return {
      group,
      resources: [...sortedResources, ...completedResources],
    };
  });
};

const ResourcesList: React.FC = () => {

  const [isResourceModelOpen, setIsResourceModelOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState<ResourceWithInfluence | null>(null);
  const [resourceModalKey, setResourceModalKey] = useState(Date.now().toString());

  const resourcesStatus = useAppSelector(getResourcesStatus);
  const resources = useAppSelector(selectAllResources);

  const totalCoins = useAppSelector(selectTotalCoins);
  const { currentLevel } = getUserLevelAndNext(totalCoins);

  const dispatch = useAppDispatch();
  const referralStatus = useAppSelector(getReferralStatus);
  const referrals = useAppSelector(selectAllReferrals);

  useEffect(() => {
    if (referralStatus === 'idle') {
      dispatch(fetchReferrals())
    }
  }, [getReferralStatus])

  const openPermanentBoostersModal = (resource: ResourceWithInfluence) => {
    setSelectedResource(resource);
    setResourceModalKey(Date.now().toString());
    setIsResourceModelOpen(true);
  }

  if (resourcesStatus !== 'succeeded') return <HBSpinner />

  const mappedResrouces = resources.map((r: any) => ({
    ...r,
    disabled: currentLevel.level < r.unlockLevel || !getUnlockClause(resources, r.unlockClause, referrals.length)
  }))
  const groupedResources = groupResources(mappedResrouces);

  return (
    // This is hack to fix ion-page-invisible because we have nested IonPage
    <IonPage style={{ opacity: 1 }}>
      <IonContent fullscreen>
        <IonGrid className="assets-grid">
          {groupedResources.map(({ group, resources }, index) => (
            <Fragment key={index}>
              <IonRow>
                <div className='capitalize text-xl mt-4 mb-2 ml-2'>{group} ({resources.length.toString()})</div>
              </IonRow>
              {resources.reduce((acc, resource, idx) => {
                if (idx % 2 === 0) acc.push([]);
                acc[acc.length - 1].push(resource);
                return acc;
              }, [] as ResourceWithInfluence[][]).map((resourceRow, rowIndex) => (
                <IonRow key={rowIndex}>
                  {resourceRow.map((resource: ResourceWithInfluence, colIndex) => (
                    <IonCol key={colIndex}>
                      <MarketCard
                        image={resource.icon}
                        title={resource.name}
                        profitPerHour={resource.idleCoins}
                        level={resource.level}
                        price={resource.price}
                        disabled={resource.disabled}
                        onClick={() => openPermanentBoostersModal(resource)}
                      />
                    </IonCol>
                  ))}
                  {resourceRow.length % 2 !== 0 && <IonCol />}
                </IonRow>
              ))}
            </Fragment>
          ))}
        </IonGrid>
      </IonContent>
      <div key={resourceModalKey}>
        <ResourceModal isOpen={isResourceModelOpen} onDidDismiss={() => setIsResourceModelOpen(false)} resource={selectedResource} />
      </div>
    </IonPage>
  );
};

export default ResourcesList;
