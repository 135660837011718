import React, { Fragment, useEffect, useRef, useState } from 'react';
import { IonIcon, IonModal, IonButton, useIonToast } from '@ionic/react';
import './BingoModal.scss';
import { chevronDownOutline, chevronUpOutline, closeOutline } from 'ionicons/icons';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import env from '../../environments';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { addCoinsFromOrder, incrementLotteryTickets } from '../../redux/userSlice';
import HBSpinner from '../HBSpinner';
import {
  fetchAdViewStatus,
  getAdsgramStatus,
  selectRemainingViews,
  startAdView,
  completeAdView,
  abandonAdView,
  selectCurrentAdView,
  selectLastWatchedAt
} from '../../redux/adsgramSlice';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';

interface BingoModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}

const InfoCard = ({ icon, title, content }: { icon: IconNameEnum, title: string, content: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col p-4 text-white rounded-lg hb-background mb-4">
      <div className={`flex ${isOpen ? 'items-start' : 'items-center'}`}>
        <HBIcon name={icon} size={32} className="mr-4 mt-1 flex-shrink-0" />
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <h2 className="text-base font-bold text-left">{title}</h2>
            {!isOpen && (
              <IonIcon
                icon={chevronDownOutline}
                className="text-white cursor-pointer"
                onClick={() => setIsOpen(true)}
              />
            )}
          </div>
          {isOpen && (
            <div className="mt-2">
              <p className="text-sm text-zinc-300 text-left">{content}</p>
              <div className="flex justify-end mt-2">
                <IonIcon
                  icon={chevronUpOutline}
                  className="text-white cursor-pointer"
                  onClick={() => setIsOpen(false)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const BingoModal: React.FC<BingoModalProps> = ({ isOpen, onDidDismiss }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const dispatch = useAppDispatch();

  const adsgramStatus = useAppSelector(getAdsgramStatus);
  const remainingViews = useAppSelector(selectRemainingViews);
  const currentAdView = useAppSelector(selectCurrentAdView);
  const lastWatchedAt = useAppSelector(selectLastWatchedAt);

  const { trackEvent } = useAnalytics();

  const [present] = useIonToast();
  const [adController, setAdController] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);

  useEffect(() => {
    if ((window as any).Adsgram) {
      try {
        const AdController = (window as any).Adsgram.init({ blockId: env.adsGramBlock });
        setAdController(AdController);
      } catch (e) {
        console.error(e);
      }
    }

    if (adsgramStatus === 'idle') {
      dispatch(fetchAdViewStatus());
    }
  }, [dispatch, adsgramStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (lastWatchedAt) {
        const timeSinceLastWatch = Date.now() - new Date(lastWatchedAt).getTime();
        const remainingCooldown = Math.max(0, 15 * 60 * 1000 - timeSinceLastWatch);
        setCooldownTime(Math.ceil(remainingCooldown / 1000));
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastWatchedAt]);

  const showToast = (message: string, color: string) => {
    present({
      message,
      duration: 3000,
      position: 'top',
      color
    });
  };

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  const handleWatchAd = async () => {
    if (!adController) {
      showToast('Ad controller not initialized', 'danger');
      return;
    }

    setIsLoading(true);

    try {
      const startResult = await dispatch(startAdView()).unwrap();
      console.log('Ad view started:', startResult);

      await delay(500);

      const result = await adController.show();

      if (result.done) {
        await delay(500);

        const completeResult = await dispatch(completeAdView()).unwrap();
        console.log('Ad view completed:', completeResult);

        dispatch(addCoinsFromOrder({ amount: 5000 }));
        dispatch(incrementLotteryTickets());
        showToast('Ad completed successfully! You earned 1 ticket and 5000 coins.', 'success');
      } else {
        await dispatch(abandonAdView()).unwrap();
        showToast('Ad was not completed. No rewards earned.', 'warning');
      }
    } catch (error) {
      console.error('Error during ad process:', error);
      showToast('Something went wrong with the ad. Please try again later.', 'danger');

      if (currentAdView.id) {
        dispatch(abandonAdView());
      }
    } finally {
      setIsLoading(false);
    }

    dispatch(fetchAdViewStatus());
  };

  const isButtonDisabled = isLoading || remainingViews === 0 || cooldownTime > 0;

  const getButtonText = () => {
    if (isLoading) {
      return <HBSpinner color="primary" className="w-6 h-6" />;
    }
    if (cooldownTime > 0) {
      const minutes = Math.floor(cooldownTime / 60);
      const seconds = cooldownTime % 60;
      return `Next ad in ${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
    if (remainingViews > 0) {
      return `Earn Tickets Now - ${remainingViews} views left`;
    }
    return 'Come back tomorrow - Daily quota fulfilled';
  };

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content bingo-modal'>
        <div className='flex justify-end text-3xl mb-4'>
          <IonIcon src={closeOutline} onClick={() => {
            trackEvent('button', 'click', 'nft teaser close modal on x');
            modal.current?.dismiss();
          }}></IonIcon>
        </div>

        {adsgramStatus !== 'succeeded' ? <HBSpinner /> :
          <Fragment>
            <div className='flex flex-col items-center text-white mb-6'>
              <HBIcon name={IconNameEnum.BingoTicket} size={92} className='icon' />
            </div>

            <div className='text-center text-white mb-4'>
              <h2 className='text-3xl mb-4'>Win Amazing Prizes!</h2>
              <p className='text-lg mb-8 text-zinc-400'>Join the Lottery Today - Only for Players</p>

              <InfoCard
                icon={IconNameEnum.One}
                title="Watch ads to earn tickets"
                content="Each ad gives you 1 ticket and 5000 coins automatically added to your in-game balance. You can watch up to 20 ads per day, with a 15-minute cooldown between views."
              />

              <InfoCard
                icon={IconNameEnum.Two}
                title="Use your tickets to enter multiple lottery pools"
                content="Increase your chances of winning by entering various lottery pools with your earned tickets. More tickets mean more opportunities to win amazing prizes!"
              />
            </div>

            <div className='text-center text-sm text-gray-400 mb-6'>
              * More information coming soon, but for now, start collecting your tickets!
            </div>

            <IonButton
              expand='block'
              color={isButtonDisabled ? 'medium' : 'primary'}
              onClick={() => {
                if (!isButtonDisabled) {
                  trackEvent('button', 'click', 'watch AdsGram ad');
                  handleWatchAd();
                }
              }}
              disabled={isButtonDisabled}
            >
              {getButtonText()}
            </IonButton>
          </Fragment>
        }
      </div>
    </IonModal>
  );
};

export default BingoModal;
