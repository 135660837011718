import { useEffect } from 'react';
import { useHistory } from 'react-router';

export const useHideIonTabBar = () => {
  const history = useHistory();

  useEffect(() => {
    document.body.classList.add("hide-ion-tab-bar");

    return history.listen(() => {
      document.body.classList.remove('hide-ion-tab-bar')
    });
  }, [history]);
};
