import React, { useEffect, Fragment, useState } from 'react';
import { IonPage, IonContent, IonButton, IonLabel, IonRadioGroup, IonRadio, IonFooter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchCharacters, selectAllCharacters, getCharactersStatus, getCharactersError, Character, CharacterProduct, CharacterEnvironment, CharacterType } from '../redux/charactersSlice';
import { useHistory, useLocation } from 'react-router-dom';
import { selectApiaryId, setCharacter, setCharacterImage, setEnvironment } from '../redux/userSlice';
import './CharacterSelector.scss';
import { updateApiaryCharacter } from '../redux/apiarySlice';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import HBSpinner from '../components/HBSpinner';
import CharacterCard from '../components/CharacterCard';

export const charactersColors = ['#32CD32', '#FF4500', '#f0b90a'];

const CharacterSelector: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const characters = useAppSelector(selectAllCharacters);
  const charactersStatus = useAppSelector(getCharactersStatus);
  const error = useAppSelector(getCharactersError);
  const apiaryId = useAppSelector(selectApiaryId);
  const history = useHistory();

  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const toggleExpand = (id: number) => {
    setExpandedItems(prevState =>
      prevState.includes(id) ? prevState.filter(i => i !== id) : [...prevState, id]
    )
  }

  const [selectedCharacter, setSelectedCharacter] = useState<Character>();

  useHideIonTabBar();

  useEffect(() => {
    if (charactersStatus === 'idle') {
      dispatch(fetchCharacters());
    } else if (charactersStatus === 'succeeded') {
      setSelectedCharacter(characters[0])
    }
  }, [charactersStatus, dispatch]);


  const handleCharacterSelect = async () => {
    if (apiaryId && selectedCharacter) {
      await dispatch(updateApiaryCharacter({ apiaryId, characterId: selectedCharacter.id }));
      dispatch(setCharacterImage(selectedCharacter.image))
      dispatch(setCharacter({ character: selectedCharacter }))
      history.push('/buzz');
    }
  };

  const filterProductAdvantages = (arr: CharacterProduct[]) => {
    return arr.filter(i => i.influencePercentage > 0);
  }

  const filterProductDisadvantages = (arr: CharacterProduct[]) => {
    return arr.filter(i => i.influencePercentage < 0);
  }

  const countProductDisadvantages = (arr: CharacterProduct[]): number => {
    return filterProductDisadvantages(arr).length;
  }

  const filterEnvAdvantages = (arr: CharacterEnvironment[]) => {
    return arr.filter(i => i.influencePercentage > 0);
  }

  const filterEnvDisadvantages = (arr: CharacterEnvironment[]) => {
    return arr.filter(i => i.influencePercentage < 0);
  }

  const countEnvDisadvantages = (arr: CharacterEnvironment[]): number => {
    return filterEnvDisadvantages(arr).length;
  }

  if (!location.pathname.startsWith('/character')) return;

  let content;

  if (charactersStatus === 'loading') {
    content = <HBSpinner />;
  } else if (charactersStatus === 'succeeded') {
    content = (
      <div className=''>
        <IonRadioGroup value={selectedCharacter?.id.toString()} onIonChange={(ev) => {
          ev.stopPropagation();
          const sc = characters.find(c => c.id == ev.detail.value);
          if (sc) {
            setSelectedCharacter(sc)
          }
        }}>
          {characters.map((char, index) => (
            <Fragment key={index}>
              <CharacterCard
                character={char}
                expanded={expandedItems.includes(index)}
                selected={selectedCharacter?.id === char.id}
                color={charactersColors[index]}
                disabled={char.type === CharacterType.PREMIUM}
                onSelectedCharacter={() => setSelectedCharacter(char)}
                onToggleExpand={() => toggleExpand(index)}>
                <IonRadio className='absolute top-1 right-1' value={char.id.toString()}></IonRadio>
              </CharacterCard>
            </Fragment>
          ))}
        </IonRadioGroup>
      </div>
    );
  } else if (charactersStatus === 'failed') {
    content = <div>{error}</div>;
  }

  return (
    <IonPage className='character-selector'>
      <IonContent>
        <h1 className='text-center text-2xl font-bold mt-4'>Select Your Bee Character</h1>
        {content}
      </IonContent>
      <IonFooter>
        <IonButton onClick={() => handleCharacterSelect()} className='p-4' expand="full" color="primary">Next</IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default CharacterSelector;
