export const REF_PARAM_PREFIX: string = 'ref_id';


export const levels = [
  {
    name: 'Novice Beekeeper',
    value: 0,
    label: '0',
    image: '/assets/beekeeper_novice.png',
    color: '#FFA07A', // Light Salmon,
    level: 0,
  },
  {
    name: 'Apprentice Beekeeper',
    value: 5000,
    label: '5K',
    image: '/assets/beekeeper_apprentice.png',
    color: '#20B2AA', // Light Sea Green
    level: 1,
  },
  {
    name: 'Journeyman Beekeeper',
    value: 25000,
    label: '25K',
    image: '/assets/beekeeper_journeyman.png',
    color: '#8A2BE2', // Blue Violet
    level: 2,
  },
  {
    name: 'Expert Beekeeper',
    value: 100000,
    label: '100K',
    image: '/assets/beekeeper_expert.png',
    color: '#FFD700', // Gold
    level: 3,
  },
  {
    name: 'Master Beekeeper',
    value: 1000000,
    label: '1M',
    image: '/assets/beekeeper_master.png',
    color: '#FF6347', // Tomato
    level: 4,
  },
  {
    name: 'Artisan Beekeeper',
    value: 10000000,
    label: '10M',
    image: '/assets/beekeeper_artisan.png',
    color: '#4682B4', // Steel Blue
    level: 5,
  },
  {
    name: 'Virtuoso Beekeeper',
    value: 50000000,
    label: '50M',
    image: '/assets/beekeeper_virtuoso.png',
    color: '#FF4500', // Orange Red
    level: 6,
  },
  {
    name: 'Grandmaster Beekeeper',
    value: 100000000,
    label: '100M',
    image: '/assets/beekeeper_grandmaster.png',
    color: '#6A5ACD', // Slate Blue
    level: 7,
  },
  {
    name: 'Sage Beekeeper',
    value: 500000000,
    label: '500M',
    image: '/assets/beekeeper_sage.png',
    color: '#32CD32', // Lime Green
    level: 8,
  },
  {
    name: 'Lord of Beekeeping',
    value: 1000000000,
    label: '1B',
    image: '/assets/beekeeper_lord.png',
    color: '#800080', // Purple
    level: 9,
  },
  {
    name: 'Eternal Beekeeper',
    value: 10000000000,
    label: '10B+',
    image: '/assets/beekeeper_eternal.png',
    color: '#f0b90a', // Primary Color
    level: 10,
  },
];