import { createContext, useContext, useEffect, ReactNode, useState } from "react";
import { Platform, initSwipeBehavior, mockTelegramEnv, parseInitData, retrieveLaunchParams, useViewport } from "@telegram-apps/sdk-react";
import env from '../environments';

interface MiniAppContextProps { }

interface MiniAppProviderProps {
  children: ReactNode;
}

const MiniAppContext = createContext<MiniAppContextProps | undefined>(undefined);

const mockTelegramData = () => {
  if (env.name === 'local' || env.name === 'development') {
    const initDataRaw = new URLSearchParams([
      ['user', JSON.stringify({
        id: 99281932,
        first_name: 'Michael',
        last_name: 'Johnson',
        username: 'rogue',
        language_code: 'en',
        is_premium: true,
        allows_write_to_pm: true,
      })],
      ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
      ['auth_date', '1716922846'],
      ['start_param', 'debug'],
      ['chat_type', 'sender'],
      ['chat_instance', '8428209589180549439'],
    ]).toString();

    mockTelegramEnv({
      themeParams: {
        accentTextColor: '#6ab2f2',
        bgColor: '#17212b',
        buttonColor: '#5288c1',
        buttonTextColor: '#ffffff',
        destructiveTextColor: '#ec3942',
        headerBgColor: '#17212b',
        hintColor: '#708499',
        linkColor: '#6ab3f3',
        secondaryBgColor: '#232e3c',
        sectionBgColor: '#17212b',
        sectionHeaderTextColor: '#6ab3f3',
        subtitleTextColor: '#708499',
        textColor: '#f5f5f5',
      },
      initData: parseInitData(initDataRaw),
      initDataRaw,
      version: '7.2',
      platform: 'android',
    });
  }
};

const isMobilePlatform = (platform: Platform): boolean => {
  const lowercasePlatform = platform.toLowerCase();

  return (
    lowercasePlatform === 'android' ||
    lowercasePlatform === 'android_x' ||
    lowercasePlatform === 'ios' ||
    lowercasePlatform.includes('ios') ||
    lowercasePlatform.includes('android')
  );
};

export const MiniAppProvider = ({ children }: MiniAppProviderProps) => {

  try {
    const viewport = useViewport();
    if (!viewport?.isExpanded) {
      // Expand mini app
      viewport?.expand();
    }
  } catch (e) {
    mockTelegramData();

    if (env.name === 'production' || env.name === 'staging') {
      // TODO: investigate why sometimes user come here even if they use Telegram mobile app
      // For now we are just going to disable it
      // if (!window.location.pathname.startsWith('/unsupported')) {
      //   window.location.href = '/unsupported?platform=undefined';
      // }
    }
  }

  useEffect(() => {
    try {
      const launchParams = retrieveLaunchParams();
      console.log('Platform: ', launchParams.platform);

      if (!isMobilePlatform(launchParams.platform) && !window.location.pathname.startsWith('/unsupported')) {
        window.location.href = `/unsupported?platform=${launchParams.platform}`;
      }

      if (launchParams.platform === 'ios') {
        document.body.classList.add('ios-platform');
      }

      const [swipeBehavior] = initSwipeBehavior();
      swipeBehavior?.disableVerticalSwipe();
    } catch (e) {
      mockTelegramData();
    }
  }, []);

  return (
    <MiniAppContext.Provider value={{}}>
      {children}
    </MiniAppContext.Provider>
  );
};

export const useMiniApp = (): MiniAppContextProps => {
  const context = useContext(MiniAppContext);
  if (context === undefined) {
    throw new Error('useMiniApp must be used within a MiniAppProvider');
  }
  return context;
};