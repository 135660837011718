import React, { Fragment } from 'react';
import { IonBadge, IonCard, IonIcon, IonLabel, IonRadio } from '@ionic/react';
import './CharacterCard.scss';
import { Character, CharacterEnvironment, CharacterProduct, CharacterType } from '../../redux/charactersSlice';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

type IfProps = {
  children?: React.ReactNode;
};

const If: React.FC<IfProps> = ({ children }) => {
  if (children) {
    return children;
  }
  return null;
};


interface CharacterCardProps {
  character: Character;
  expanded: boolean;
  selected: boolean;
  color: string;
  disabled: boolean;
  onSelectedCharacter: () => void;
  onToggleExpand: () => void;
  children?: React.ReactNode;
  classNames?: string;
}

const CharacterCard: React.FC<CharacterCardProps> = ({ character, expanded, selected, onSelectedCharacter, color, children, onToggleExpand, classNames, disabled }) => {

  const filterProductAdvantages = (arr: CharacterProduct[]) => {
    return arr.filter(i => i.influencePercentage > 0);
  }

  const filterProductDisadvantages = (arr: CharacterProduct[]) => {
    return arr.filter(i => i.influencePercentage < 0);
  }

  const countProductDisadvantages = (arr: CharacterProduct[]): number => {
    return filterProductDisadvantages(arr).length;
  }

  const filterEnvAdvantages = (arr: CharacterEnvironment[]) => {
    return arr.filter(i => i.influencePercentage > 0);
  }

  const filterEnvDisadvantages = (arr: CharacterEnvironment[]) => {
    return arr.filter(i => i.influencePercentage < 0);
  }

  const countEnvDisadvantages = (arr: CharacterEnvironment[]): number => {
    return filterEnvDisadvantages(arr).length;
  }

  return (
    <IonCard disabled={disabled} onClick={() => onSelectedCharacter()} className={`flex gap-4 p-4 ${classNames}`}>
      <img className='w-24 h-24 rounded-md mt-2' src={`assets/` + character.image.replace('.jpg', '_transparent.png')} style={{ filter: `drop-shadow(0px 0px 20px ${color})` }} alt={character.name} />
      <div className="">
        <div className='text-xl text-white mb-2' style={{ color: selected ? 'var(--ion-color-primary)' : '' }}>{character.name}</div>
        {character.type === CharacterType.PREMIUM && <IonBadge>Premium</IonBadge>}
        <div className='text-zinc-200'>{character.description}</div>
        {expanded &&
          <div className='mt-4 text-zinc-200'>
            <IonLabel className='text-base'><b>Advantages:</b></IonLabel>
            <ul className='advantages-list mb-8 mt-2'>
              {filterProductAdvantages(character.characterProduct).map((adv, idx: number) => (
                <li key={idx} className='mb-2'>Increase <b>{adv.product.name}</b> production for <b>+{adv.influencePercentage * 100}%</b></li>
              ))}

              {filterEnvAdvantages(character.characterEnvironment).map((adv, idx: number) => (
                <li key={idx} className='mb-2'><b>{adv.environment.name}</b> resistance <b>+{adv.influencePercentage * 100}%</b></li>
              ))}
            </ul>
            {(countProductDisadvantages(character.characterProduct) > 0 || countEnvDisadvantages(character.characterEnvironment) > 0) && (
              <Fragment>
                <IonLabel className='text-base'><b>Disadvantages:</b></IonLabel>
                <ul className='mt-2'>
                  {filterProductDisadvantages(character.characterProduct).map((adv, idx: number) => (
                    <li key={idx} className='mb-2'>Reduce <b>{adv.product.name}</b> production for <b>{adv.influencePercentage * 100}%</b></li>
                  ))}

                  {filterEnvDisadvantages(character.characterEnvironment).map((adv, idx: number) => (
                    <li key={idx} className='mb-2'><b>{adv.environment.name}</b> resistance <b>{adv.influencePercentage * 100}%</b></li>
                  ))}
                </ul>
              </Fragment>
            )}
          </div>}
      </div>
      <IonIcon onClick={() => onToggleExpand()} className='absolute bottom-1 right-2 text-white' size='small' icon={expanded ? chevronUpOutline : chevronDownOutline } color='light' />
      <If>
        {children}
      </If>
    </IonCard>
  );
};

export default CharacterCard;
