import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Status } from './store';
import axiosInstance from '../axiosConfig';

export interface DailyTask {
  day: number;
  reward: number;
  isCompleted: boolean;
  claimingDate: Date;
}

interface UserState {
  id: number;
  timeZone: string;
  currentCoins: number;
  dailyLoginTasks: DailyTask[];
  totalDailyLoginTasksEarnings: number;
  status: Status;
  error: string | null;
}

const initialState: UserState = {
  id: 0,
  timeZone: '',
  currentCoins: 0,
  dailyLoginTasks: [],
  totalDailyLoginTasksEarnings: 0,
  status: 'idle',
  error: null,
};

// Async thunk for fetching the user state
export const fetchUserState = createAsyncThunk(
  'tasks/fetchUserState',
  async () => {
    const response = await axiosInstance.get(`/tasks/state`);
    return response.data;
  }
);

// Async thunk for claiming the daily task reward
export const claimDailyTaskReward = createAsyncThunk(
  'tasks/claimDailyTaskReward',
  async ({ timeZone }: { timeZone: string }) => {
    const response = await axiosInstance.post(`/tasks/daily/login`, { timeZone });
    return response.data;
  }
);

const tasksSlice = createSlice({
  name: 'dailyTasks',
  initialState,
  reducers: {
    addTotalDailyLoginTasksEarnings(state, action: PayloadAction<{ amount: number }>) {
      state.totalDailyLoginTasksEarnings += action.payload.amount;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserState.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.id = action.payload.id;
        state.timeZone = action.payload.timeZone;
        state.currentCoins = action.payload.currentCoins;
        state.dailyLoginTasks = action.payload.dailyLoginTasks;
        state.totalDailyLoginTasksEarnings = action.payload.totalDailyLoginTasksEarnings;
      })
      .addCase(fetchUserState.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(claimDailyTaskReward.fulfilled, (state) => {
        const currentTask = state.dailyLoginTasks.find(task => !task.isCompleted);
        if (currentTask) {
          currentTask.isCompleted = true;
        }
      })
  },
});

export const {
  addTotalDailyLoginTasksEarnings
} = tasksSlice.actions;

export default tasksSlice.reducer;

export const selectUserState = (state: any) => state.dailyTasks;
export const selectDailyLoginTasks = (state: any) => state.dailyTasks.dailyLoginTasks;
export const selectCurrentCoins = (state: any) => state.dailyTasks.currentCoins;
export const selectDailyTasksStatus = (state: any) => state.dailyTasks.status;
export const selectDailyTasksError = (state: any) => state.dailyTasks.error;
export const selectTotalDailyLoginTasksEarnings = (state: any) => state.dailyTasks.totalDailyLoginTasksEarnings;
