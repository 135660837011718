import React from 'react';
import { IonSpinner } from '@ionic/react';
import './HBSpinner.scss';

const HBSpinner: React.FC<{ className?: string, color?: string  }> = ({ className = '', color = 'primary' }) => {

  return <div className={`flex items-center justify-center w-full hb-spinner ${className}`}>
    <IonSpinner className='w-12 h-12' name="crescent" color={color}></IonSpinner>
  </div>
};

export default HBSpinner;
