import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { Status } from './store';

export enum TaskType {
  INVITE_3_FRIENDS = 'INVITE_3_FRIENDS'
}

// Define the state interface
export interface Task {
  id: number;
  type: TaskType,
  title: string;
  description: string;
  isCompleted: boolean;
  reward: number;
  startedAt: string | null; // Allow null for tasks not started yet
}

interface TaskState {
  tasks: Task[];
  status: Status;
  error: string | null;
}

// Initial state
const initialState: TaskState = {
  tasks: [],
  status: 'idle',
  error: null,
};

// Create an async thunk to fetch video tasks from the API
export const fetchTasks = createAsyncThunk('tasks/fetchTasks', async () => {
  const response = await axiosInstance.get('/tasks');
  return response.data;
});

export const claimTasksReward = createAsyncThunk('tasks/claimTasksReward', async (taskId: number) => {
  const response = await axiosInstance.put(`/tasks/${taskId}/claim`);
  return response.data;
});

// Create the slice
const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch video tasks';
      })
      .addCase(claimTasksReward.fulfilled, (state, action) => {
        const task = state.tasks.find(t => t.id === action.payload.task.id);
        if (task) {
          task.startedAt = new Date().toISOString();
          task.isCompleted = true;
        }
      });
  },
});

export default taskSlice.reducer;

export const selectAllTasks = (state: { tasks: TaskState }) => state.tasks.tasks;
export const selectTasksStatus = (state: { tasks: TaskState }) => state.tasks.status;
export const getTasksError = (state: { tasks: TaskState }) => state.tasks.error;
