import React, { useRef, useState, useEffect } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon, IonLabel } from '@ionic/react';
import { useAppDispatch } from '../../hooks';
import './VideoTaskModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';
import Coin from '../Coin';
import { VideoTask, claimVideoTasksReward, startVideoTasks } from '../../redux/videoTasksSlice';
import { DateTime } from 'luxon';
import { formatNumber } from '../../utils';
import { addCoinsFromOrder } from '../../redux/userSlice';

interface DailyTaskModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  task: VideoTask;
}

const VideoTaskWrapper: React.FC<{isOpen: boolean, onDidDismiss: () => void, task: VideoTask | null }> = ({ isOpen, onDidDismiss, task }) => {

  if (!task) return;

  return <VideoTaskModal isOpen={isOpen} onDidDismiss={onDidDismiss} task={task} />
}

const VideoTaskModal: React.FC<DailyTaskModalProps> = ({ isOpen, onDidDismiss, task }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const dispatch = useAppDispatch();
  const [present] = useIonToast();
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);

  const [startedAt, setStartedAt] = useState(task.startedAt);

  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;

    if (!task.isCompleted && startedAt) {
      const updateTimer = () => {
        const minutesDifference = Math.floor(DateTime.now().diff(DateTime.fromISO(startedAt || ''), 'minutes').minutes);
        const remainingTime = Math.max(task.requiredWatchTimeMinutes - minutesDifference, 0);
        setTimeLeft(remainingTime);
        setIsButtonEnabled(remainingTime === 0);
      };

      updateTimer(); // Initial call to set the timer immediately
      timerId = setInterval(updateTimer, 60000); // Check every minute

      return () => {
        if (timerId) clearInterval(timerId); // Clean up interval on component unmount
      };
    }

  }, [task, startedAt]);

  const rewardClaimedToast = () => {
    present({
      message: 'Reward successfully claimed!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const handleStartVideoTasks = async (activeTask: VideoTask) => {
    if (!startedAt) {
      dispatch(startVideoTasks(activeTask.id));
      setStartedAt(new Date().toISOString());
    }
    window.open(activeTask.videoUrl, '_blank')
  };

  const handleClaimReward = async (activeTask: VideoTask | null) => {
    if (activeTask) {
      dispatch(claimVideoTasksReward(activeTask.id));

      dispatch(addCoinsFromOrder({ amount: activeTask.reward }));
      rewardClaimedToast();
      modal.current?.dismiss();
    }
  };

  return (
    <IonModal  ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content video-task-modal'>
        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={IconNameEnum.Youtube} className='icon' size={72} />
          </div>

          <h2 className='text-3xl mb-4 text-center'>{task?.title}</h2>
          <p className='text-center text-sm mb-4'>{task?.description}</p>

          <IonButton className='mb-4' onClick={() => handleStartVideoTasks(task)}>
            <div className='px-8'>Watch</div>
          </IonButton>

          {startedAt && !task.isCompleted && timeLeft > 0 && (
            <IonLabel class='text-center mb-4'>
              Wait {timeLeft > 59 ? '1h' : `${timeLeft} min`} for moderation to check to claim the prize
            </IonLabel>
          )}

          <div className='flex text-2xl font-medium'>
            <Coin size={28} /> +{formatNumber(task?.reward || 0)}
          </div>
        </div>

        <IonButton disabled={!isButtonEnabled} color={isButtonEnabled ? 'primary' : 'medium'} className='mb-6' expand='block' onClick={() => handleClaimReward(task)}>
          Check
        </IonButton>
      </div>
    </IonModal>
  );
};

export default VideoTaskWrapper;
