import { Config } from ".";

const development: Config = {
  name: 'development',
  apiBaseUrl: 'https://buzzy-bee-api-dev.hivebits.io',
  telegramBot: 'hb_bb_dev_bot',
  adsGramBlock: '3830',
};

export default development;
