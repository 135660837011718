import React, { useRef } from 'react';
import { IonIcon, IonModal, IonButton, IonList, IonItem, IonLabel } from '@ionic/react';
import './NFTTeaserModal.scss';
import { chevronForward, closeOutline } from 'ionicons/icons';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { useHistory } from 'react-router';
import { useAnalytics } from '../../contexts/AnalyticsContext';
import { useAppSelector } from '../../hooks';
import { selectTwitterCredentials } from '../../redux/userSlice';

interface NFTTeaserModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}

const NFTTeaserModal: React.FC<NFTTeaserModalProps> = ({ isOpen, onDidDismiss }) => {

  const modal = useRef<HTMLIonModalElement>(null);
  const history = useHistory();

  const { trackEvent } = useAnalytics();

  const twitterCredentials = useAppSelector(selectTwitterCredentials);

  const handleConnectTwitter = () => {
    if (twitterCredentials) return;

    trackEvent('button', 'click', 'nft teaser connect twitter');
    modal.current?.dismiss();
    history.push('/settings?connect-twitter=true');
  };

  const handleGoToEarnTab = () => {
    trackEvent('button', 'click', 'nft teaser open earn tab');
    modal.current?.dismiss();
    history.push('/earn');
  };
  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content nft-modal'>

        {/* Close button */}
        <div className='flex justify-end text-3xl mb-4'>
          <IonIcon src={closeOutline} onClick={() => {
            trackEvent('button', 'click', 'nft teaser close modal on x');
            modal.current?.dismiss()
          }}></IonIcon>
        </div>

        {/* GIF Image */}
        <div className='flex flex-col items-center text-white mb-6'>
          <img className='icon w-56 rounded-md' src={`/assets/NFT-Teaser.gif`} alt="NFT Teaser GIF" />
        </div>

        {/* Catchy Text */}
        <div className='text-center text-white mb-4'>
          <h2 className='text-3xl mb-4'>Enter for a chance to win a $100 NFT!</h2>
          <p className='text-lg mb-8 text-zinc-400'>Exclusive Offer - Only for Players</p>


          <IonList className='list rounded-md'>
            <IonItem className='rounded-md mb-4' onClick={() => handleConnectTwitter()}>
              <HBIcon name={IconNameEnum.One} size={32} className='mr-4' />
              <IonLabel>Connect your Twitter account</IonLabel>
              { !twitterCredentials && <IonIcon icon={chevronForward} /> }
              { twitterCredentials && <HBIcon name={IconNameEnum.Check} size={24} /> }
            </IonItem>
            <IonItem className='rounded-md mb-4' onClick={() => handleGoToEarnTab()}>
              <HBIcon name={IconNameEnum.Two} size={32} className='mr-4' />
              <IonLabel>Complete all X tasks</IonLabel>
              <IonIcon slot='end' icon={chevronForward} />
            </IonItem>
          </IonList>
        </div>

        <div className='text-center text-sm text-gray-400 mb-6'>
          * Launch date will be announced soon. Make sure to like, repost, and comment on each post that appears in the tasks list.
        </div>

        <IonButton expand='block' onClick={() => {
          trackEvent('button', 'click', 'nft teaser close modal on got it');
          modal.current?.dismiss()
        }}>
          Got it!
        </IonButton>

      </div>
    </IonModal>
  );
};

export default NFTTeaserModal;
