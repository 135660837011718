// redux/apiarySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, Status } from './store';
import axiosInstance from '../axiosConfig';

interface ApiaryState {
  apiary: any;
  status: Status;
  error: string | null;
}

const initialState: ApiaryState = {
  apiary: null,
  status: 'idle',
  error: null
};

const apiarySlice = createSlice({
  name: 'apiary',
  initialState,
  reducers: {
    updateApiaryStart(state) {
      state.status = 'loading';
    },
    updateApiarySuccess(state, action: PayloadAction<any>) {
      state.apiary = action.payload;
      state.status = 'succeeded';
    },
    updateApiaryFailure(state, action: PayloadAction<string>) {
      state.status = 'failed';
      state.error = action.payload;
    }
  }
});

export const { updateApiaryStart, updateApiarySuccess, updateApiaryFailure } = apiarySlice.actions;

export const updateApiaryEnvironment = ({ apiaryId, environmentId }: { apiaryId: number, environmentId: number }): AppThunk => async (dispatch) => {
  try {
    dispatch(updateApiaryStart());
    const token = localStorage.getItem('token');
    const response = await axiosInstance.patch(`/apiaries/${apiaryId}/environment`, { environmentId }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(updateApiarySuccess(response.data));
  } catch (error: any) {
    dispatch(updateApiaryFailure(error.toString()));
  }
};

export const updateApiaryCharacter = ({ apiaryId, characterId }: { apiaryId: number, characterId: number }): AppThunk => async (dispatch) => {
  try {
    dispatch(updateApiaryStart());
    const token = localStorage.getItem('token');
    const response = await axiosInstance.patch(`/apiaries/${apiaryId}/character`, { characterId }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(updateApiarySuccess(response.data));
  } catch (error: any) {
    dispatch(updateApiaryFailure(error.toString()));
  }
};

export default apiarySlice.reducer;
