import React, { useState, useEffect, useCallback, createContext, useContext, ReactNode } from 'react';
import { CloudStorage, initCloudStorage } from '@telegram-apps/sdk-react';

// Assuming you have an environment configuration file
import env from '../environments';

// Helper function to check if we're in a Telegram WebApp
const isTelegramWebApp = (): boolean => {
  if (env.name === 'local') {
    return false;
  }
  return (window as any).Telegram && ((window as any).Telegram.WebView || (window as any).Telegram.WebApp) ? true : false;
};

// Type definitions
type StorageType = CloudStorage | Storage | null;

interface StorageContextType {
  storage: StorageType;
  getItem: (key: string) => Promise<string | null>;
  setItem: (key: string, value: string) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  isInitialized: boolean;
  storageValues: Record<string, string | null>;
}

interface StorageProviderProps {
  children: ReactNode;
}

// Create the context
const StorageContext = createContext<StorageContextType | null>(null);

// Provider component
export const StorageProvider: React.FC<StorageProviderProps> = ({ children }) => {
  const [storage, setStorage] = useState<StorageType>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [storageValues, setStorageValues] = useState<Record<string, string | null>>({});

  // Initialize storage
  useEffect(() => {
    const initStorage = async () => {
      console.log('Initializing storage...');
      if (isTelegramWebApp()) {
        try {
          const cloudStorage = await initCloudStorage();
          setStorage(cloudStorage);
          console.log('Cloud storage initialized');
        } catch (error) {
          console.error('Failed to initialize CloudStorage:', error);
          setStorage(localStorage);
          console.log('Fallback to local storage');
        }
      } else {
        console.log('Using local storage');
        setStorage(localStorage);
      }
      setIsInitialized(true);
    };

    initStorage();
  }, []);

  // Helper function to wait for storage initialization
  const waitForInitialization = async (): Promise<void> => {
    if (!isInitialized) {
      console.warn('Storage not yet initialized. Waiting...');
      await new Promise<void>(resolve => {
        const checkInitialized = () => {
          if (isInitialized) {
            resolve();
          } else {
            setTimeout(checkInitialized, 100);
          }
        };
        checkInitialized();
      });
    }
  };

  // Get item from storage
  const getItem = useCallback(async (key: string): Promise<string | null> => {
    await waitForInitialization();
    if (!storage) return null;

    let value: string | null = null;
    if (storage instanceof CloudStorage) {
      value = await storage.get(key);
    } else {
      value = storage.getItem(key);
    }
    setStorageValues(prev => ({ ...prev, [key]: value }));
    return value;
  }, [storage, isInitialized]);

  // Set item in storage
  const setItem = useCallback(async (key: string, value: string): Promise<void> => {
    await waitForInitialization();
    if (!storage) return;

    if (storage instanceof CloudStorage) {
      await storage.set(key, value);
    } else {
      storage.setItem(key, value);
    }
    setStorageValues(prev => ({ ...prev, [key]: value }));
  }, [storage, isInitialized]);

  // Remove item from storage
  const removeItem = useCallback(async (key: string): Promise<void> => {
    await waitForInitialization();
    if (!storage) return;

    if (storage instanceof CloudStorage) {
      await storage.delete(key);
    } else {
      storage.removeItem(key);
    }
    setStorageValues(prev => {
      const newValues = { ...prev };
      delete newValues[key];
      return newValues;
    });
  }, [storage, isInitialized]);

  return (
    <StorageContext.Provider value={{
      storage,
      getItem,
      setItem,
      removeItem,
      isInitialized,
      storageValues
    }}>
      {children}
    </StorageContext.Provider>
  );
};

// Custom hook to use the storage context
export const useStorage = () => {
  const context = useContext(StorageContext);
  if (context === null) {
    throw new Error('useStorage must be used within a StorageProvider');
  }
  return context;
};
