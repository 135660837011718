import local from './local';
import development from './development';
import staging from './staging';
import production from './production';

export interface Config {
  name: 'local' | 'development' | 'staging' | 'production'
  apiBaseUrl: string;
  telegramBot: 'hb_bb_dev_bot' | 'hb_bb_stg_bot' | 'hivebits_bot';
  tgToken?: string;
  tgName?: string;
  adsGramBlock: string;
}

const env = import.meta.env.VITE_ENV || 'local';

console.log('env', env)

let config: Config;

switch (env) {
  case 'local':
    config = local;
    break;
  case 'development':
  case 'dev':
    config = development;
    break;
  case 'staging':
    config = staging;
    break;
  case 'production':
    config = production;
    break;
  default:
    config = local;
}

export default config;