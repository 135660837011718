import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { Status } from './store';

// Define the initial state
interface ReferralState {
  referrals: any[];
  status: Status;
  error: string | null;
}

const initialState: ReferralState = {
  referrals: [],
  status: 'idle',
  error: null,
};

// Async thunk to fetch referral data
export const fetchReferrals = createAsyncThunk(
  'referrals/fetchReferrals',
  async () => {
    const response = await axiosInstance.get(`/referrals`);
    return response.data;
  }
);

// Create the referral slice
const referralSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferrals.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchReferrals.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.referrals = action.payload;
      })
      .addCase(fetchReferrals.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export default referralSlice.reducer;

export const selectAllReferrals = (state: any): any[] => state.referrals.referrals;
export const getReferralStatus = (state: any): Status => state.referrals.status;
export const getReferralError = (state: any): string | null => state.referrals.error;