
import React, { useRef, useState } from 'react';
import { IonContent, IonIcon, IonPage, IonProgressBar } from '@ionic/react';
import './BoostPage.scss';
import Slider from 'react-slick';
import { chevronBack, chevronForward } from 'ionicons/icons';
import './LevelsPage.scss'
import HBBack from '../components/HBBack';
import { useAppSelector } from '../hooks';
import { formatNumber, getUserLevelAndNext } from '../utils';
import { selectTotalCoins } from '../redux/userSlice';
import { levels } from '../constants';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const LevelsPage: React.FC = () => {

  const sliderRef = useRef<Slider>(null);
  const totalCoins = useAppSelector(selectTotalCoins)

  const { levelIndex, nextLevel, percentageToNextLevel } = getUserLevelAndNext(totalCoins);

  const [currentIndex, setCurrentIndex] = useState(levelIndex)

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  if (!location.pathname.startsWith('/levels')) return;

  return (
    <IonPage>
      <IonContent fullscreen>
        <HBBack />
        <div>
          <button
            className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-2xl p-2 z-10 top-25p"
            onClick={previous}
          >
            <IonIcon icon={chevronBack} />
          </button>
          <Slider ref={sliderRef} afterChange={(currentSlide) => setCurrentIndex(currentSlide)} initialSlide={levelIndex}  {...sliderSettings}>
            {levels.map((level, index) => (
              <div className='h-96 mt-16' key={index}>
                <span className='flex flex-col items-center mt-16'>
                  <img className='animate__animated animate__flip' key={index} src={level.image} style={{ width: "14rem", height: "auto", filter: `drop-shadow(0px 0px 20px ${level.color})` }} />
                </span>
              </div>
            ))}
          </Slider>

          <div className='flex flex-col items-center mt-4 animate__animated animate__lightSpeedInRight'>
            <div className='text-2xl mb-1 font-bold'>{levels[currentIndex].name}</div>
            {
              levelIndex === currentIndex ?
                <div className='text-base text-zinc-400'>{formatNumber(totalCoins)} / {nextLevel.label}</div> :
                <div className='text-base text-zinc-400'>from {levels[currentIndex].label}</div>
            }

            {levelIndex === currentIndex && <IonProgressBar className='h-2 w-64 mt-2' style={{ '--progress-background': levels[currentIndex].color }} value={percentageToNextLevel} />}
          </div>
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white text-2xl p-2 top-25p"
            onClick={next}
          >
            <IonIcon icon={chevronForward} />

          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LevelsPage;
