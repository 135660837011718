import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Status } from './store';
import axiosInstance from '../axiosConfig';

export interface Resource {
  id: number;
  key: number;
  name: string;
  icon: string;
  basePrice: number;
  basePercentage: number;
  unlockClause: string;
  level: number;
  idleCoins: number;
}

interface ResourcesState {
  resources: Resource[];
  status: Status;
  error: string | null;
}

const initialState: ResourcesState = {
  resources: [],
  status: 'idle',
  error: null,
};

export const MAX_LEVEL = 12;

// Async thunk to fetch resources with levels for a specific apiary
export const fetchResourcesWithLevelsForApiary = createAsyncThunk(
  'resources/fetchResourcesWithLevelsForApiary',
  async () => {
    const response = await axiosInstance.get<Resource[]>(`/resources/apiary`);
    return response.data;
  }
);

// Async thunk to upgrade a resource
export const upgradeResource = createAsyncThunk(
  'resources/upgradeResource',
  async ({ resourceId }: { resourceId: number }) => {
    const response = await axiosInstance.put(`/resources/apiary`, { resourceId });
    return response.data;
  }
);

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesWithLevelsForApiary.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchResourcesWithLevelsForApiary.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.resources = action.payload;
      })
      .addCase(fetchResourcesWithLevelsForApiary.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(upgradeResource.fulfilled, (state, action) => {
        const updatedResource = action.payload;
        const index = state.resources.findIndex(resource => resource.id === updatedResource.id);
        if (index !== -1) {
          state.resources[index] = updatedResource;
        }
      })
      .addCase(upgradeResource.rejected, (state, action) => {
        state.error = action.error.message || null;
      });
  },
});

export default resourcesSlice.reducer;

export const selectAllResources = (state: any) => state.resources.resources;
export const getResourcesStatus = (state: any) => state.resources.status;
export const getResourcesError = (state: any) => state.resources.error;
