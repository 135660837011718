import { IonCard, IonItem, IonList } from "@ionic/react";
import HBIcon from "../HBIcon";
import { IconNameEnum } from "../HBIcon/HBIcon";
import Coin from "../Coin";
import { formatNumber } from "../../utils";
import './ListOfFriends.scss';

const ListOfFriends: React.FC<{ referrals: any[] }> = ({ referrals }) => {

  return (
    <div className="list-of-friends">
      {!referrals.length ? <IonCard className='ion-padding'>

        <p>You haven't invited anyone yet</p>
      </IonCard> :

        <IonList className="px-4" style={{ background: 'none' }}>
          {referrals.map((ref, index) => (
            <IonItem className="mb-2" key={index}>
              <HBIcon size={32} name={IconNameEnum.Apiarist} className="mr-2" />
              <div className="text-lg whitespace-nowrap overflow-hidden text-ellipsis">{ref.firstName}</div>
              <div slot="end" className="flex text-lg">
                <Coin size={24} /> +{formatNumber(ref.reward)}
              </div>
            </IonItem>
          ))}
        </IonList>
      }
    </div>
  );
}

export default ListOfFriends;