import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonSkeletonText, IonToggle, useIonToast } from '@ionic/react';
import './Login.scss';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import HBBack from '../components/HBBack';
import { chevronForward } from 'ionicons/icons';
import DeleteAccountModal from '../components/DeleteAccountModal';
import CharacterCard from '../components/CharacterCard';
import { useAppDispatch, useAppSelector } from '../hooks';
import { disconnectTwitter, selectApiaries, selectTwitterCredentials } from '../redux/userSlice';
import { charactersColors } from './CharacterSelector';
import EnvironmentCard from '../components/EnvironmentCard';
import { fetchCharacter, selectCharacter, selectCharacterStatus } from '../redux/charactersSlice';
import { fetchEnvironment, selectEnvironment, selectEnvironmentStatus } from '../redux/environmentsSlice';
import HBIcon from '../components/HBIcon';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import env from '../environments';
import { useAnalytics } from '../contexts/AnalyticsContext';
import { useStorage } from '../contexts/StorageContext';
import { useLocation } from 'react-router';
import './SettingsPage.scss';

export const VIBRATE_ON_TAP_KEY = 'vibrate_on_tap_key';
export const COINS_ANIMATION_KEY = 'coins_animation_key';

const SettingsPage: React.FC = () => {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const apiaries = useAppSelector(selectApiaries);
  let apiary = null;
  if (apiaries && apiaries.length > 0) {
    apiary = apiaries[0];
  }

  useHideIonTabBar();

  const { trackEvent } = useAnalytics();

  const dispatch = useAppDispatch();

  const [expandChar, setExpandChar] = useState(false);
  const [expandEnv, setExpandEnv] = useState(false);

  const characterStatus = useAppSelector(selectCharacterStatus);
  const environmentStatus = useAppSelector(selectEnvironmentStatus);
  const twitterCredentials = useAppSelector(selectTwitterCredentials);

  const character = useAppSelector(selectCharacter);
  const environment = useAppSelector(selectEnvironment);

  const { getItem, setItem, isInitialized } = useStorage();

  const [vibrateOnTap, setVibrateOnTap] = useState(true);
  const [coinsAnimation, setCoinsAnimation] = useState(true);

  const twitterItemRef = useRef<HTMLIonItemElement>(null);

  const toggleVibration = (value: boolean) => {
    setVibrateOnTap(value);
    setItem(VIBRATE_ON_TAP_KEY, value.toString());
  }

  const toggleCoinsAnimation = (value: boolean) => {
    setCoinsAnimation(value);
    setItem(COINS_ANIMATION_KEY, value.toString());
  }

  useEffect(() => {
    setTimeout(async () => {
      const noVibration = await getItem(VIBRATE_ON_TAP_KEY) === 'false';
      const noCoinsAnimation = await getItem(COINS_ANIMATION_KEY) === 'false';

      setCoinsAnimation(!noCoinsAnimation);
      setVibrateOnTap(!noVibration);
    }, 1000)
  }, [getItem])

  const location = useLocation();
  const [focusTwitter, setFocusTwitter] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const shouldFocusTwitter = searchParams.get('connect-twitter') !== null;
    setFocusTwitter(shouldFocusTwitter);

    if (shouldFocusTwitter && twitterItemRef.current) {
      setTimeout(() => {
        twitterItemRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 500);
    }
  }, [location]);


  const [present] = useIonToast();
  const twitterDisconnectedToast = () => {
    present({
      message: 'X successfully disconnected!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const somethingWentWrongToast = () => {
    present({
      message: 'Something went wrong.',
      duration: 1000,
      position: 'top',
      color: 'danger'
    });
  }

  const connectTwitter = async () => {
    if (twitterCredentials) {
      trackEvent('button', 'click', 'disconnect twitter');
      const res: any = await dispatch(disconnectTwitter());
      if (res.error) {
        somethingWentWrongToast();
      } else {
        twitterDisconnectedToast();
      }
    } else {
      trackEvent('button', 'click', 'connect twitter');
      window.location.href = `${env.apiBaseUrl}/auth/twitter/connect?token=${localStorage.getItem('token')}`;
    }
  }

  useEffect(() => {
    if (apiary && characterStatus === 'idle') {
      dispatch(fetchCharacter(apiary.character.id))
    }

    if (apiary && environmentStatus === 'idle') {
      dispatch(fetchEnvironment(apiary.environment.id))
    }
  }, [characterStatus, environmentStatus, apiary])

  return (
    <IonPage>
      <IonContent className='settings-page'>
        <HBBack route='buzz' />

        <div className='text-center p-4'>
          <h2 className='text-3xl mb-8'>Settings</h2>

          {environment &&
            <div className='text-left mb-4'>
              <IonLabel class='text-base'>Environment</IonLabel>
              <EnvironmentCard disabled={false} classNames='hb-background mx-0' environment={environment} expanded={expandEnv} selected={false} onSelectedEnvironment={() => { }} onToggleExpand={() => setExpandEnv(!expandEnv)} />
            </div>
          }

          {character &&
            <div className='text-left mb-8'>
              <IonLabel class='text-base'>Character</IonLabel>
              <CharacterCard classNames='hb-background mx-0' disabled={false} character={character} color={charactersColors[character.id - 1]} expanded={expandChar} selected={false} onSelectedCharacter={() => setExpandChar(!expandChar)} onToggleExpand={() => { }} />
            </div>
          }

          <IonList className='rounded-md mb-8 bg-transparent'>
            <IonItem className='rounded-md hb-background mb-4'>
              {isInitialized ?
                <IonToggle checked={vibrateOnTap} onIonChange={({ detail: { checked } }) => toggleVibration(checked)}>Vibrate on Tap</IonToggle> :
                <IonSkeletonText animated={true} className='rounded-md h-4'></IonSkeletonText>
              }
            </IonItem>
            <IonItem className='rounded-md hb-background mb-4'>
              {isInitialized ?
                <IonToggle checked={coinsAnimation} onIonChange={({ detail: { checked } }) => toggleCoinsAnimation(checked)}>Coins Animation</IonToggle> :
                <IonSkeletonText animated={true} className='rounded-md h-4'></IonSkeletonText>
              }
            </IonItem>
          </IonList>

          <IonList className='rounded-md mb-8 bg-transparent'>
            <IonItem ref={twitterItemRef} onClick={() => {
              connectTwitter();
              setFocusTwitter(false);
            }} className={`rounded-md hb-background mb-4 ${focusTwitter ? 'hb-border mx-auto mt-2 animate__animated animate__pulse animate__infinite animate__slow' : ''}`}>
              <HBIcon name={IconNameEnum.Twitter} size={28} className='mr-2' />
              <div className='w-64 truncate text-left'>
                {twitterCredentials ? `Disconnect X (${twitterCredentials.screenName})` : 'Connect X (Twitter) account'}
              </div>
              <IonIcon slot='end' icon={chevronForward} />
            </IonItem>
            <IonItem onClick={() => setIsDeleteModalOpen(true)} className='rounded-md hb-background mb-4'>
              <IonLabel>Delete account</IonLabel>
              <IonIcon slot='end' icon={chevronForward} />
            </IonItem>
          </IonList >

          <a href='https://hivebits.io/tap-app/privacy-policy' target='_blank'>
            <IonLabel color='light' className='underline'>Privacy policy</IonLabel>
          </a>

          <p className='w-full text-center text-zinc-400 text-sm mt-8'>v3.0.0-beta</p>
        </div>



        <DeleteAccountModal isOpen={isDeleteModalOpen} onDidDismiss={() => setIsDeleteModalOpen(false)} />
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
