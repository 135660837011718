// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import {  IonButton, IonModal, IonIcon } from '@ionic/react';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

interface ProductsModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
}

const ProductsPage: React.FC<ProductsModalProps> = ({ isOpen, onDidDismiss }) => {

  const history = useHistory();
  const modal = useRef<HTMLIonModalElement>(null);


  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content'>
        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={IconNameEnum.Rocket} className='icon' size={72} />
          </div>

          <div className='text-3xl mb-2 text-center'>Boost your profit</div>

          <p className='text-sm mb-4'>
            Tap the Apiary menu to upgrade your resources
          </p>

          <p>
            Earn even when offline up to 3 hours
          </p>
        </div>

        <IonButton color='primary' className='mb-6' expand='block' onClick={() => {
          modal.current?.dismiss();
          history.push('/apiary');
        }}>
          Boost your profit
        </IonButton>

      </div>
    </IonModal>
  );
};

export default ProductsPage;
