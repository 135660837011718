// CoinsAnimationContext.tsx
import React, { createContext, useState, useContext, useCallback, ReactNode, PropsWithChildren } from 'react';

interface CoinsAnimationContextType {
  isAnimationActive: boolean;
  triggerAnimation: () => void;
  resetAnimation: () => void;
}

const CoinsAnimationContext = createContext<CoinsAnimationContextType | undefined>(undefined);

export const CoinsAnimationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  const triggerAnimation = useCallback(() => {
    setIsAnimationActive(true);
  }, []);

  const resetAnimation = useCallback(() => {
    setIsAnimationActive(false);
  }, []);

  const value = { isAnimationActive, triggerAnimation, resetAnimation };

  return (
    <CoinsAnimationContext.Provider value={value}>
      {children}
    </CoinsAnimationContext.Provider>
  );
};

export const useCoinsAnimation = (): CoinsAnimationContextType => {
  const context = useContext(CoinsAnimationContext);
  if (!context) {
    throw new Error('useCoinsAnimation must be used within a CoinsAnimationProvider');
  }
  return context;
};
