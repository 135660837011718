import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, Status } from './store';
import { IconNameEnum } from '../components/HBIcon/HBIcon';
import axiosInstance from '../axiosConfig';

export const calculateBoosterPrice = (basePrice: number, level: number) => basePrice * Math.pow(2, level);

export enum PermanentBoosterType {
  ENERGY = 'energy',
  TAP = 'tap',
}

export interface PermanentBooster {
  id: number;
  type: PermanentBoosterType,
  name: string;
  description: string;
  specification: string;
  basePrice: number;
  increment: number;
  newLevel: number;
  newPrice: number;
}

interface UserPermanentBooster {
  id: number;
  userId: number;
  permanentBoosterId: number;
  permanentBooster: PermanentBooster;
  level: number;
  createdAt: Date;
  updatedAt: Date;
}

interface PermanentBoostersState {
  boosters: PermanentBooster[];
  status: Status;
  error: string | null;
}

const initialState: PermanentBoostersState = {
  boosters: [],
  status: 'idle',
  error: null,
};

export const fetchPermanentBoosters = createAsyncThunk<PermanentBooster[]>(
  'permanentBoosters/fetchPermanentBoosters',
  async () => {
    const response = await axiosInstance.get('/permanent-boosters');
    return response.data;
  }
);

export const addPermanentBoosterToUser = createAsyncThunk<UserPermanentBooster, { boosterId: number }>(
  'permanentBoosters/addPermanentBoosterToUser',
  async ({ boosterId }) => {
    const response = await axiosInstance.post(`/permanent-boosters/add-booster/${boosterId}`, {}, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    });
    return response.data;
  }
);

const permanentBoostersSlice = createSlice({
  name: 'permanentBoosters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermanentBoosters.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchPermanentBoosters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.boosters = action.payload;
      })
      .addCase(fetchPermanentBoosters.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch permanent boosters';
      })
      .addCase(addPermanentBoosterToUser.pending, (state) => {;
        state.error = null;
      })
      .addCase(addPermanentBoosterToUser.fulfilled, (state, action) => {
        const { permanentBooster, level } = action.payload;
        state.boosters =  state.boosters.map(booster => {
          if (booster.id === permanentBooster.id) {
            return {
              ...booster,
              newLevel: level + 1,
              newPrice: calculateBoosterPrice(booster.basePrice, level),
            };
          }
          return booster;
        });
      })
      .addCase(addPermanentBoosterToUser.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to add permanent booster to user';
      });
  },
});

export const selectAllPermanentBoosters = (state: RootState) => state.permanentBoosters.boosters;
export const selectPermanentBoostersStatus = (state: RootState) => state.permanentBoosters.status;


export default permanentBoostersSlice.reducer;
