import React, { useEffect, useState } from 'react';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import './CoinsAnimation.scss';
import { initHapticFeedback } from '@telegram-apps/sdk-react';

interface CoinsAnimationProps {
  isActive: boolean;
  onAnimationComplete: () => void;
}

export const COINS_TARGET_ATTR = 'data-coins-target';

const CoinsAnimation: React.FC<CoinsAnimationProps> = ({ isActive, onAnimationComplete }) => {
  const [coins, setCoins] = useState<{ id: number; delay: number }[]>([]);
  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);

  const hapticFeedback = initHapticFeedback();

  useEffect(() => {
    const targetElements = document.querySelectorAll(`[${COINS_TARGET_ATTR}]`);
    let visibleTargetElement: Element | null = null;

    for (const element of targetElements) {
      const rect = element.getBoundingClientRect();
      if (rect.width > 0 && rect.height > 0 &&
        rect.top >= 0 && rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {
        visibleTargetElement = element;
        break;
      }
    }

    if (visibleTargetElement) {
      setTargetRect(visibleTargetElement.getBoundingClientRect());
    } else {
      setTargetRect(null);
    }

    if (isActive) {
      const hapticInterval = setInterval(() => {
        hapticFeedback.notificationOccurred('success');
      }, 199);

      const newCoins = Array.from({ length: 30 }, (_, i) => ({
        id: i,
        delay: Math.random() * 500,
      }));
      setCoins(newCoins);

      const timer = setTimeout(() => {
        clearInterval(hapticInterval);
        setCoins([]);
        onAnimationComplete();
      }, 1000);

      return () => {
        clearInterval(hapticInterval);
        clearTimeout(timer);
      };
    }
  }, [isActive, onAnimationComplete]);

  const targetPosX = targetRect ? `${targetRect.left}px` : '50vw';
  const targetPosY = targetRect ? `${-window.innerHeight + targetRect.top + targetRect.height}px` : '-70vh';

  return (
    <div className="coin-animation-container">
      {coins.map(({ id, delay }) => (
        <div
          key={id}
          className="coin"
          style={{
            animationDelay: `${delay}ms`,
            '--random-x': `${Math.random() * 100}vw`,
            '--target-x': targetPosX,
            '--target-y': targetPosY,
          } as React.CSSProperties}
        >
          <HBIcon name={IconNameEnum.Coin} size={32} />
        </div>
      ))}
    </div>
  );
};

export default CoinsAnimation;