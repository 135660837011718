// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './DailyBoostersModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { regenerateFullEnergy, selectCurrentCoins } from '../../redux/userSlice';
import { closeOutline } from 'ionicons/icons';
import { formatNumber } from '../../utils';
import { DailyBooster, DailyBoosterType, addDailyBoosterToUser, boosterUsed } from '../../redux/dailyBoostersSlice';
import { dailyBoostersIcons } from '../../pages/BoostPage';

interface DailyBoosterModalProps {
  booster: DailyBooster | null;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const DailyBoosterModal: React.FC<DailyBoosterModalProps> = ({ booster, isOpen, onDidDismiss }) => {
  const currentCoins = useAppSelector(selectCurrentCoins);

  const modal = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const [present] = useIonToast();
  const boostAddedToast = () => {
    present({
      message: 'Booster successfully added!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const handleAddBooster = async ({id: boosterId, type}: DailyBooster) => {
    await dispatch(addDailyBoosterToUser({
      boosterId: boosterId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }));

    if (type === DailyBoosterType.FULL_ENERGY) {
      dispatch(regenerateFullEnergy());
      dispatch(boosterUsed({ boosterId }));
    }

    boostAddedToast();
    modal.current?.dismiss();
  }

  if (!booster) return;

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-7'>
            <HBIcon name={dailyBoostersIcons[booster.type]} size={92} />
          </div>

          <h2 className='text-2xl mb-4'>{booster.name}</h2>

          <p className='mb-8 text-center'>
            {booster.description}
          </p>

          <div className='flex items-center text-3xl mb-8'>
            <HBIcon name={IconNameEnum.Coin} size={36} className='mr-2' /> {booster.basePrice > 0 ? formatNumber(booster.basePrice) : 'Free'}
          </div>
        </div>

        <IonButton className='mb-6' expand='block' onClick={() => handleAddBooster(booster)}>
          Go ahead
        </IonButton>
      </div>
    </IonModal>
  );
};

export default DailyBoosterModal;
