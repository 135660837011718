import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import './ApiaryTab.scss';
import Points from '../components/Coins';
import ProfileHeader from '../components/ProfileHeader';
import OrdersList from '../components/OrdersList/OrdersList';
import ResourcesList from '../components/Resources';
import { useLocation } from 'react-router';
import ProductsStock from '../components/ProductsStock';
import { fetchResourcesWithLevelsForApiary, getResourcesStatus } from '../redux/resourcesSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchStock, getStockStatus } from '../redux/stockSlice';
import { fetchProducts, getProductsStatus } from '../redux/productSlice';

enum Segment {
  RESOURCE = 'resource',
  STOCK = 'stock',
  ORDERS = 'orders'
}

const ApiaryTab: React.FC = () => {
  const location = useLocation();
  const [selectedSegment, setSelectedSegment] = useState<Segment>(Segment.RESOURCE);

  const dispatch = useAppDispatch();
  const resourcesStatus = useAppSelector(getResourcesStatus);
  const stockStatus = useAppSelector(getStockStatus);
  const productsStatus = useAppSelector(getProductsStatus);


  const handleSegmentChange = (e: CustomEvent) => {
    setSelectedSegment(e.detail.value);
  };

  useEffect(() => {
    if (resourcesStatus === 'idle') {
      dispatch(fetchResourcesWithLevelsForApiary())
    }

    if (stockStatus === 'idle') {
      dispatch(fetchStock())
    }

    if (productsStatus === 'idle') {
      dispatch(fetchProducts());
    }
  }, [dispatch, resourcesStatus, stockStatus, productsStatus])

  if (!location.pathname.startsWith('/apiary')) return;

  return (
    <IonPage>
      <IonContent fullscreen>
        <ProfileHeader />
        <div className="hb-padding">
          <Points />
          <IonSegment value={selectedSegment} onIonChange={handleSegmentChange}>
            <IonSegmentButton value={Segment.RESOURCE}>
              <IonLabel>Resource</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={Segment.STOCK}>
              <IonLabel>Stock</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={Segment.ORDERS}>
              <IonLabel>Orders</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="router-container">
            {selectedSegment === Segment.RESOURCE && <ResourcesList />}
            {selectedSegment === Segment.STOCK && <ProductsStock />}
            {selectedSegment === Segment.ORDERS && <OrdersList />}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ApiaryTab;
