// src/redux/productSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { Status } from './store';

export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const response = await axiosInstance.get('/products'); // Adjust the URL to your API endpoint
  return response.data;
});

export interface Product {
  id: number;
  name: string;
  productionRatePerHour: number;
  unlockLevel: number;
  valueInCoins: number;
  image: string;
}

interface ProductsState {
  products: Product[];
  status: Status;
  error: string | null;
}

const initialState: ProductsState = {
  products: [],
  status: 'idle',
  error: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setStatusIdle(state) {
      state.status = 'idle';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export const {
  setStatusIdle
} = productsSlice.actions;

export default productsSlice.reducer;

export const selectAllProducts = (state: any): Product[] => state.products.products;
export const getProductsStatus = (state: any): Status => state.products.status;
export const getProductsError = (state: any): string | null => state.products.error;
export const getProfitPerHour = (state: any): number => state.products.products.filter((item: any) => item.unlocked)
  .reduce((total: number, item: any) => total + (item.productionRatePerHour * item.valueInCoins), 0);
